import styled from 'styled-components';

import { IoCheckmarkSharp, IoChevronForwardSharp } from 'react-icons/io5';
import {
  InStoreSalesChannelType,
  Merchant,
  MerchantIntegration,
  MerchantIntegrationStatus,
  MerchantIntegrationType,
  MerchantRentalConfiguration,
  OnlineSalesChannelType,
  PolicyTableValues,
  QRCodeIntegrationSettings,
  ReferralLinkIntegrationSettings,
  RentalPolicyState,
  WaiverEntry,
  WaiverState
} from '@oysterjs/types';
import { LinkComponent } from '@oysterjs/ui/Button';

const WaypointContainer = styled.div`
  a,
  a:visited {
    color: #000000;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const WaypointTitle = styled.div`
  color: #999999;
  font-weight: 600;
  font-size: 0.7em;
  text-transform: uppercase;
`;

const WaypointNextStep = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-weight: 400;
    font-size: 1.4em;
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    color: #000000;
  }

  svg {
    margin-top: 0.25em;
    font-size: 1.2em;
  }
`;

const WaypointNextStepDescription = styled.p`
  font-size: 0.8em;
  color: #666666;
  margin-top: 0;
`;

const WaypointProgressBarContainer = styled.div`
  position: relative;
  height: 11px;
`;

const WaypointProgressBarTrack = styled.div`
  width: calc(100% - 2px);
  border-top: 1px solid #eaeaea;
  position: absolute;
  top: 5px;
  left: 0;
`;

const WaypointProgressBarStep = styled.div`
  margin-top: 2px;
  padding-right: 2px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const WaypointProgressBarStepInner = styled.div<{ completed?: boolean }>`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: ${(props) => (props.completed ? '#7dd3fc' : '#D2D2D2')};
`;

const WaypointProgressBarInner = styled.div<{ completed: number }>`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  height: 100%;
  background: #0ea5e9;
  width: ${(props) => props.completed}%;
`;

const WaypointProgressBarStepContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
`;

const WaypointProgress = (props: { steps: Step[] }) => {
  const completedSteps = props.steps.filter((s) => s.completed);

  return (
    <WaypointProgressBarContainer>
      <WaypointProgressBarTrack />
      <WaypointProgressBarInner completed={(100 * completedSteps.length) / props.steps.length} />
      <WaypointProgressBarStepContainer>
        {props.steps.map((_, i) => (
          <WaypointProgressBarStep key={i}>
            <WaypointProgressBarStepInner completed={i < completedSteps.length} />
          </WaypointProgressBarStep>
        ))}
      </WaypointProgressBarStepContainer>
    </WaypointProgressBarContainer>
  );
};

const WaypointStepsContainer = styled.ol`
  margin: 1.5em 0 0 0;
  list-style: none;
  border-top: 1px solid #f2f2f2;
  padding: 16px 0px;
`;

const WaypointStepContainer = styled.li`
  margin: 0;
  padding: 8px 0px;
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const WaypointStepCheckmarkContainer = styled.div<{ completed?: boolean }>`
  border-radius: 50%;
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.8em;
  background: ${(props) => (props.completed ? '#0EA5E9' : 'transparent')};
  border: ${(props) => (props.completed ? '0' : '1px solid #e2e2e2')};
`;

const WaypointStep = (props: { step: Step }) => (
  <WaypointStepContainer>
    <div style={{ display: 'inline-block' }}>
      <WaypointStepCheckmarkContainer completed={props.step.completed}>
        {props.step.completed && <IoCheckmarkSharp />}
      </WaypointStepCheckmarkContainer>
    </div>
    <div style={{ display: 'inline-block' }}>
      {props.step.action ? (
        <LinkComponent href={props.step.action}>{props.step.title}</LinkComponent>
      ) : (
        props.step.title
      )}
    </div>
  </WaypointStepContainer>
);

const WaypointSteps = (props: { steps: Step[] }) => (
  <WaypointStepsContainer>
    {props.steps.map((step) => (
      <WaypointStep key={step.title} step={step} />
    ))}
  </WaypointStepsContainer>
);

interface Step {
  title: string;
  action?: string;
  description?: string;
  completed?: boolean;
}

const getSteps = (
  merchant: Merchant,
  businessPolicies: string[],
  integrations: MerchantIntegration[],
  rentalWaivers: WaiverEntry[],
  rentalConfiguration?: MerchantRentalConfiguration
): Step[] => {
  //////////////////////////////////////////////
  // Business insurance steps                 //
  //////////////////////////////////////////////
  const shouldShowBusinessInsuranceSteps =
    merchant.BusinessProfile.Personalization.BusinessInsuranceEnabled;

  const businessInsuranceAppDone =
    merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted ||
    businessPolicies.length > 0;

  //////////////////////////////////////////////
  // Product insurance steps                  //
  //////////////////////////////////////////////
  const shouldShowProductInsuranceSteps =
    merchant.BusinessProfile.Personalization.ProductInsuranceEnabled;

  // Get integration settings
  const referralSettings = integrations.find(
    (i) => i.Type === MerchantIntegrationType.referral_link
  )?.Settings as ReferralLinkIntegrationSettings | undefined;
  const qrCodeSettings = integrations.find((i) => i.Type === MerchantIntegrationType.qr_code)
    ?.Settings as QRCodeIntegrationSettings | undefined;
  const shopifyIntegration = integrations.find((i) => i.Type === MerchantIntegrationType.shopify);
  const lightspeedIntegration = integrations.find(
    (i) => i.Type === MerchantIntegrationType.lsretail_rseries
  );
  const wooCommerceIntegration = integrations.find(
    (i) => i.Type === MerchantIntegrationType.woocommerce
  );

  // Determine if steps are relevant
  const shouldSetupShopifyIntegration =
    merchant.BusinessProfile.Personalization.OnlineSalesChannelType ===
    OnlineSalesChannelType.Shopify;
  const shouldSetupLightspeedIntegration =
    merchant.BusinessProfile.Personalization.InStoreSalesChannelType ===
    InStoreSalesChannelType.LightspeedRSeries;
  const shouldSetupWooCommerceIntegration =
    merchant.BusinessProfile.Personalization.OnlineSalesChannelType ===
    OnlineSalesChannelType.WooCommerce;

  // Determine which steps are completed
  const payoutMethodStepDone =
    !!merchant.BusinessProfile?.PaymentMethod && !!merchant.MercuryRecipientID;
  const referralLinkStepDone =
    !!referralSettings?.FirstPageLoad && new Date(referralSettings?.FirstPageLoad).getTime() > 0;
  const customizeMarketingKitStepDone =
    !!qrCodeSettings?.FirstDownload && new Date(qrCodeSettings?.FirstDownload).getTime() > 0;
  const setupShopifyIntegrationStepDone =
    shopifyIntegration?.Status === MerchantIntegrationStatus.active;
  const setupLightspeedIntegrationStepDone =
    lightspeedIntegration?.Status === MerchantIntegrationStatus.active;
  const setupWooCommerceIntegrationStepDone =
    wooCommerceIntegration?.Status === MerchantIntegrationStatus.active;

  //////////////////////////////////////////////
  // Rental insurance steps                   //
  //////////////////////////////////////////////

  const shouldShowRentalInsuranceSteps =
    merchant.BusinessProfile.Personalization.RentalInsuranceEnabled;

  const rentalInsuranceAppDone = rentalConfiguration?.Details.State === RentalPolicyState.approved;
  const rentalInsuranceWaiverDone = !!rentalWaivers.find(
    (w) => w.Waiver?.State === WaiverState.active
  );

  const steps = [
    { title: 'Create your account', completed: true },
    {
      title: 'Finish your business insurance application',
      action: '/insurance',
      description: 'Finish and submit your appliation for business insurance',
      completed: businessInsuranceAppDone,
      hidden: !shouldShowBusinessInsuranceSteps
    },
    {
      title: 'Share your landing page',
      action: '/channels/referral',
      description: 'Copy and share the link to your customizable referral landing page',
      completed: referralLinkStepDone,
      hidden: !shouldShowProductInsuranceSteps
    },
    {
      title: 'Download your marketing kit',
      action: '/channels/qrcode',
      description: 'Set your branding and download your marketing kit',
      completed: customizeMarketingKitStepDone,
      hidden: !shouldShowProductInsuranceSteps
    },
    {
      title: 'Setup Shopify integration',
      action: '/channels/shopify',
      description: 'Offer Oyster to your customers in your Shopify store',
      completed: setupShopifyIntegrationStepDone,
      hidden: !shouldShowProductInsuranceSteps || !shouldSetupShopifyIntegration
    },
    {
      title: 'Setup Lightspeed integration',
      action: '/channels/lsretail-rseries',
      description: 'Offer Oyster to your customers through your Lightspeed PoS',
      completed: setupLightspeedIntegrationStepDone,
      hidden: !shouldShowProductInsuranceSteps || !shouldSetupLightspeedIntegration
    },
    {
      title: 'Setup WooCommerce integration',
      action: '/channels/woocommerce',
      description: 'Offer Oyster to your customers in your WooCommerce store',
      completed: setupWooCommerceIntegrationStepDone,
      hidden: !shouldShowProductInsuranceSteps || !shouldSetupWooCommerceIntegration
    },
    {
      title: 'Set a payout method',
      action: '/payouts',
      description: "To start earning money, choose how you'd like Oyster to pay you.",
      completed: payoutMethodStepDone,
      hidden: !shouldShowProductInsuranceSteps
    },
    {
      title: 'Set up rental insurance',
      action: '/apps/rental',
      description: 'Submit your rental insurance application and set up the app',
      completed: rentalInsuranceAppDone,
      hidden: !shouldShowRentalInsuranceSteps
    },
    {
      title: 'Create your first rental waiver',
      action: '/apps/rental',
      description: 'Create a booking and protect your first rental with a waiver',
      completed: rentalInsuranceWaiverDone,
      hidden: !shouldShowRentalInsuranceSteps
    }
  ];

  return steps.filter((s) => !s.hidden);
};

export const Waypoint = (props: {
  merchant: Merchant;
  policies: string[];
  policyReferrals?: PolicyTableValues[];
  integrations: MerchantIntegration[];
  rentalConfiguration?: MerchantRentalConfiguration;
  rentalWaivers: WaiverEntry[];
}) => {
  const steps = getSteps(
    props.merchant,
    props.policies,
    props.integrations,
    props.rentalWaivers,
    props.rentalConfiguration
  );
  let nextStep = steps.find((s) => !s.completed);

  if (!nextStep) {
    nextStep = {
      title: "You're all set!",
      description: "You've completed all the steps to set up Oyster. Congrats!"
    };
  }

  return (
    <WaypointContainer className="overflow-hidden rounded-lg bg-white shadow p-6 w-full">
      <WaypointTitle>Next Step</WaypointTitle>
      <WaypointNextStep>
        <h2>
          {nextStep.action ? (
            <LinkComponent href={nextStep.action}>{nextStep.title}</LinkComponent>
          ) : (
            nextStep.title
          )}
        </h2>
        <IoChevronForwardSharp />
      </WaypointNextStep>
      <WaypointNextStepDescription>{nextStep.description}</WaypointNextStepDescription>
      <WaypointProgress steps={steps} />
      <WaypointSteps steps={steps} />
    </WaypointContainer>
  );
};
