import React from 'react';

export const PageTitle = (
  props: React.PropsWithChildren<{
    title: string;
    description?: string | JSX.Element;
    badge?: JSX.Element;
  }>
) => (
  <div>
    <h1 className="m-0">{props.title}</h1>
    {props.badge && <div className="pt-2">{props.badge}</div>}
    {props.description && <p className="text-base mt-2 text-neutral-500">{props.description}</p>}
    <div className="mt-8">{props.children}</div>
  </div>
);

export const PageSection = (
  props: React.PropsWithChildren<{ title: string; description?: string }>
) => (
  <div style={{ padding: '24px 0px 0px 0px' }}>
    <h2 className="mb-0 font-medium text-neutral-700">{props.title}</h2>
    {props.description && <p className="text-sm mt-2 text-neutral-700">{props.description}</p>}
    <div style={{ padding: '12px 0px 0px 0px', boxSizing: 'border-box' }}>{props.children}</div>
  </div>
);
