import { Redirect, Route as BrowserRoute, Switch, useLocation } from 'react-router';
import { useRouteMatch, NavLink } from 'react-router-dom';
import * as Sentry from '@sentry/react';
const Route = Sentry.withSentryRouting(BrowserRoute);

import { Loadable } from '@oysterjs/ui/Loadable';
import {
  getMerchantAccount,
  getMerchantGraphQLClient,
  getMerchantIntegrations,
  getMerchantReferrals,
  getMerchantRentalConfiguration,
  getMerchantUsers,
  getRentalWaivers,
  merchantUserSignOut
} from '@oysterjs/core/api/merchant';
import { HomePage } from './home';
import { ProductInsurancePage } from './insurance/product';
import { PayoutsPage } from './insurance/product/payouts';
import { TeamPage } from './team';
import { RiskPage, RiskDashboardPage, ModelRiskPage, ExploreRiskPage } from './risk';
import { MerchantAppsPage } from './apps';
import { MerchantRentalPage } from './apps/rental';
import { InvitePage } from './invite';
import { InsurancePage } from './insurance/business';
import { MerchantReportsPage } from './reports';
import { ApolloProvider } from '@apollo/client';
import {
  Merchant as GraphQLMerchant,
  useGetMerchantLazyQuery
} from '@oysterjs/types/merchant/graphql/types-and-hooks';
import {
  BusinessPolicy,
  GetMerchantBusinessPoliciesQuery,
  GetMerchantBusinessPoliciesDocument,
  useGetMerchantBusinessPoliciesLazyQuery
} from '../types/graphql';
import { Spinner } from '@oysterjs/ui/Spinner';
import React from 'react';
import { Merchant, MerchantUser, ValidationError } from '@oysterjs/types';

import clsx from 'clsx';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Menu,
  MenuButton,
  TransitionChild
} from '@headlessui/react';
import {
  BanknotesIcon,
  Bars3Icon,
  BellIcon,
  ChartPieIcon,
  PowerIcon,
  GiftIcon,
  HomeIcon,
  PuzzlePieceIcon,
  RectangleStackIcon,
  ShieldCheckIcon,
  UsersIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import { OysterLogo } from '@oysterjs/ui/Logo';
import { resetToken, useAuth } from '@oysterjs/core/auth';
import { GetRiskManagementQuery, GetRiskManagementDocument } from '../types/graphql';

const navigation = [
  { name: 'Home', href: '/home', icon: HomeIcon },
  { name: 'Insurance', href: '/insurance', icon: ShieldCheckIcon },
  { name: 'Channels', href: '/channels', icon: PuzzlePieceIcon },
  { name: 'Team', href: '/team', icon: UsersIcon },
  { name: 'Payouts', href: '/payouts', icon: BanknotesIcon },
  { name: 'Apps', href: '/apps', icon: RectangleStackIcon },
  { name: 'Invite', href: '/invite', icon: GiftIcon },
  { name: 'Reports', href: '/reports', icon: ChartPieIcon }
];

const AsyncMerchantHomePage = () => (
  <Loadable
    request={Promise.all([
      getMerchantAccount(),
      getMerchantGraphQLClient()
        .query<GetMerchantBusinessPoliciesQuery>({ query: GetMerchantBusinessPoliciesDocument })
        .then((res) => res.data?.businessPolicies || []),
      getMerchantReferrals(
        new Date(new Date().getUTCFullYear(), new Date().getUTCMonth()),
        new Date(new Date().getUTCFullYear(), new Date().getUTCMonth() + 1)
      ),
      getMerchantIntegrations(),
      getMerchantRentalConfiguration(),
      getRentalWaivers()
    ])}
  >
    {([account, businessPolicies, referrals, integrations, rental, waivers]) => {
      return (
        <HomePage
          merchant={account.Merchant}
          businessPolicies={businessPolicies}
          policyReferrals={referrals.Referrals}
          integrations={integrations.Integrations || []}
          rentalConfiguration={rental.Configuration}
          rentalWaivers={waivers}
        />
      );
    }}
  </Loadable>
);

const AsyncMerchantProductInsurancePage = () => (
  <Loadable request={Promise.all([getMerchantAccount(), getMerchantIntegrations()])}>
    {([accountRes, integrationsRes]) => {
      return (
        <ProductInsurancePage
          merchant={accountRes.Merchant}
          apiKey={integrationsRes.ApiKey}
          merchantIntegrations={integrationsRes.Integrations}
        />
      );
    }}
  </Loadable>
);

const AsyncMerchantInsurancePage = () => {
  const [accountRes, setAccountRes] = React.useState<{
    Merchant: Merchant;
    MerchantUser: MerchantUser;
    PersonalizationError?: ValidationError;
  }>();
  const [merchant, setMerchant] = React.useState<GraphQLMerchant>();
  const [policies, setPolicies] = React.useState<BusinessPolicy[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [businessPoliciesQuery] = useGetMerchantBusinessPoliciesLazyQuery();
  const [getMerchantQuery] = useGetMerchantLazyQuery();

  React.useEffect(() => {
    setLoading(true);
    Promise.all([getMerchantAccount()]).then(([accountRes]) => {
      setAccountRes(accountRes);
    });
  }, []);

  React.useEffect(() => {
    Promise.all([
      new Promise<void>((resolve) =>
        businessPoliciesQuery({
          onCompleted: (d) => {
            setPolicies(d.businessPolicies as BusinessPolicy[]);
            resolve();
          },
          onError: () => resolve()
        })
      ),
      new Promise<void>((resolve) =>
        getMerchantQuery({
          onCompleted: (d) => {
            setMerchant(d.merchant as GraphQLMerchant);
            resolve();
          },
          onError: () => resolve()
        })
      )
    ]).finally(() => setLoading(false));
  }, [accountRes?.Merchant.ID]);

  return (
    <>
      {loading && <Spinner color="#333333" />}
      {!loading && accountRes?.Merchant && accountRes?.MerchantUser && merchant && (
        <InsurancePage
          merchant={accountRes.Merchant}
          merchantUser={accountRes.MerchantUser}
          graphMerchant={merchant}
          policies={policies}
        />
      )}
    </>
  );
};

const AsyncRiskPage = () => (
  <Loadable
    request={Promise.all([
      getMerchantAccount(),
      getMerchantGraphQLClient()
        .query<GetRiskManagementQuery>({ query: GetRiskManagementDocument })
        .then((res) => res.data?.riskManagement || null),
      getMerchantGraphQLClient()
        .query<GetMerchantBusinessPoliciesQuery>({ query: GetMerchantBusinessPoliciesDocument })
        .then((res) => res.data?.businessPolicies || [])
    ])}
  >
    {([account, riskManagement, businessPolicies]) => {
      return (
        <RiskPage
          merchant={account.Merchant}
          riskManagement={riskManagement}
          businessPolicies={businessPolicies}
        />
      );
    }}
  </Loadable>
);

const AsyncManageRiskPage = () => (
  <Loadable
    request={Promise.all([
      getMerchantAccount(),
      getMerchantUsers(),
      getMerchantGraphQLClient()
        .query<GetMerchantBusinessPoliciesQuery>({ query: GetMerchantBusinessPoliciesDocument })
        .then((res) => res.data?.businessPolicies || []),
      getMerchantGraphQLClient()
        .query<GetRiskManagementQuery>({ query: GetRiskManagementDocument })
        .then((res) => res.data?.riskManagement || null)
    ])}
  >
    {([account, users, businessPolicies, riskManagement]) => {
      return (
        <RiskDashboardPage
          merchant={account.Merchant}
          users={users.Users}
          businessPolicies={businessPolicies}
          riskManagement={riskManagement}
        />
      );
    }}
  </Loadable>
);

const AsyncModelRiskPage = () => (
  <Loadable request={getMerchantAccount()}>
    {() => {
      return <ModelRiskPage />;
    }}
  </Loadable>
);

const AsyncExploreRiskPage = () => (
  <Loadable
    request={Promise.all([
      getMerchantGraphQLClient()
        .query<GetMerchantBusinessPoliciesQuery>({ query: GetMerchantBusinessPoliciesDocument })
        .then((res) => res.data?.businessPolicies || [])
    ])}
  >
    {([businessPolicies]) => {
      return <ExploreRiskPage businessPolicies={businessPolicies.filter((p) => !!p.details)} />;
    }}
  </Loadable>
);

const AsyncPayoutsPage = () => (
  <Loadable request={getMerchantAccount()}>
    {(account) => {
      return <PayoutsPage merchant={account.Merchant} />;
    }}
  </Loadable>
);

const AsyncTeamPage = () => (
  <Loadable request={Promise.all([getMerchantAccount(), getMerchantUsers()])}>
    {([account, users]) => {
      return <TeamPage merchant={account.Merchant} merchantUsers={users.Users} />;
    }}
  </Loadable>
);

const AsyncInvitePage = () => (
  <Loadable request={getMerchantAccount()}>
    {(account) => {
      return <InvitePage merchant={account.Merchant} />;
    }}
  </Loadable>
);

const DashboardContainer = (props: React.PropsWithChildren<unknown>) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const location = useLocation();
  const [auth] = useAuth();
  const user = auth.MerchantUser;

  const signOut = () => {
    merchantUserSignOut().finally(() => {
      resetToken();
    });
  };

  return (
    <>
      <div>
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
          <DialogBackdrop
            transition
            className="fixed inset-0 bg-neutral-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <DialogPanel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <TransitionChild>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button
                    type="button"
                    onClick={() => setSidebarOpen(false)}
                    className="-m-2.5 p-2.5"
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-8 pb-4">
                <div className="flex py-6 px-2 shrink-0 items-center">
                  <OysterLogo inline />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-2">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className={clsx(
                                location.pathname.startsWith(item.href)
                                  ? 'bg-neutral-50 text-primary-600'
                                  : 'text-neutral-700 hover:bg-neutral-50 hover:text-primary-600',
                                'group flex gap-x-3 rounded-md py-2 px-4 text-sm font-semibold leading-6'
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className={clsx(
                                  location.pathname.startsWith(item.href)
                                    ? 'text-primary-600'
                                    : 'text-neutral-400 group-hover:text-primary-600',
                                  'h-6 w-6 shrink-0'
                                )}
                              />
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="mt-auto">
                      <a
                        onClick={signOut}
                        href="#"
                        className="group -mx-2 flex gap-x-3 rounded-md py-2 px-4 text-sm font-semibold leading-6 text-neutral-700 hover:bg-neutral-50 hover:text-primary-600"
                      >
                        <PowerIcon
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0 text-neutral-400 group-hover:text-primary-600"
                        />
                        Sign Out
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-64 lg:flex-col w">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-neutral-200 bg-white px-8 pb-4">
            <div className="flex py-6 px-2 shrink-0 items-center">
              <OysterLogo inline scale={1} />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-2">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={clsx(
                            location.pathname.startsWith(item.href)
                              ? 'bg-neutral-50 text-primary-600'
                              : 'text-neutral-700 hover:bg-neutral-50 hover:text-primary-600',
                            'group flex gap-x-3 rounded-md py-2 px-4 text-sm font-semibold leading-6'
                          )}
                        >
                          <item.icon
                            aria-hidden="true"
                            className={clsx(
                              location.pathname.startsWith(item.href)
                                ? 'text-primary-600'
                                : 'text-neutral-400 group-hover:text-primary-600',
                              'h-6 w-6 shrink-0'
                            )}
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <a
                    onClick={signOut}
                    href="#"
                    className="group -mx-2 flex gap-x-3 rounded-md py-2 px-4 text-sm font-semibold leading-6 text-neutral-700 hover:bg-neutral-50 hover:text-primary-600"
                  >
                    <PowerIcon
                      aria-hidden="true"
                      className="h-6 w-6 shrink-0 text-neutral-400 group-hover:text-primary-600"
                    />
                    Sign Out
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-64">
          <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-neutral-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              onClick={() => setSidebarOpen(true)}
              className="-m-2.5 p-2.5 text-neutral-700 lg:hidden"
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon aria-hidden="true" className="h-6 w-6" />
            </button>

            {/* Separator */}
            <div aria-hidden="true" className="h-6 w-px bg-neutral-200 lg:hidden" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="relative flex flex-1" />
              {/* <form action="#" method="GET" className="relative flex flex-1">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-neutral-400"
                />
                <input
                  id="search-field"
                  name="search"
                  type="search"
                  placeholder="Search..."
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-neutral-900 placeholder:text-neutral-400 focus:ring-0 sm:text-sm"
                />
              </form> */}
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-neutral-400 hover:text-neutral-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon aria-hidden="true" className="h-6 w-6" />
                </button>

                {/* Separator */}
                <div
                  aria-hidden="true"
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-neutral-200"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <MenuButton className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>

                    <div className="rounded-full h-10 w-10 bg-primary-200/50 flex items-center justify-center font-medium text-sm">
                      {user?.FirstName[0]}
                      {user?.LastName[0]}
                    </div>
                    <span className="hidden lg:flex lg:items-center">
                      <span
                        aria-hidden="true"
                        className="ml-2 text-sm font-semibold leading-6 text-neutral-900"
                      >
                        {user?.FirstName} {user?.LastName}
                      </span>
                      {/* <ChevronDownIcon
                        aria-hidden="true"
                        className="ml-2 h-5 w-5 text-neutral-400"
                      /> */}
                    </span>
                  </MenuButton>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-8 sm:py-12 bg-neutral-50 min-h-lvh">
            <div className="px-8 sm:px-12">{props.children}</div>
          </main>
        </div>
      </div>
    </>
  );
};

export const Dashboard = () => {
  const { path } = useRouteMatch();

  return (
    <DashboardContainer>
      <Switch>
        <Route path={`${path.replace(/\/+$/, '')}/product-insurance`}>
          <AsyncMerchantProductInsurancePage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/channels`}>
          <AsyncMerchantProductInsurancePage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/insurance`}>
          <ApolloProvider client={getMerchantGraphQLClient()}>
            <AsyncMerchantInsurancePage />
          </ApolloProvider>
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/business-insurance`}>
          <ApolloProvider client={getMerchantGraphQLClient()}>
            <AsyncMerchantInsurancePage />
          </ApolloProvider>
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/home`}>
          <AsyncMerchantHomePage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/payouts`}>
          <AsyncPayoutsPage />
        </Route>
        <Route exact path={`${path.replace(/\/+$/, '')}/risk`}>
          <AsyncRiskPage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/risk/manage`}>
          <AsyncManageRiskPage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/risk/model`}>
          <AsyncModelRiskPage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/risk/explore`}>
          <AsyncExploreRiskPage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/team`}>
          <AsyncTeamPage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/apps`}>
          <MerchantAppsPage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/rental`}>
          <MerchantRentalPage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/reports`}>
          <MerchantReportsPage />
        </Route>
        <Route path={`${path.replace(/\/+$/, '')}/invite`}>
          <AsyncInvitePage />
        </Route>
        <Redirect to="/home" />
      </Switch>
    </DashboardContainer>
  );
};
