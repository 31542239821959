import React from 'react';
import clsx from 'clsx';
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { PageTitle } from '@oysterjs/ui/Page/section';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Button as HeadlessButton
} from '@headlessui/react';
import {
  ArrowTopRightOnSquareIcon,
  BellIcon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  EyeIcon,
  ExclamationTriangleIcon as ExclamationTriangleIconOutline,
  LinkIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import {
  ArrowRightIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon
} from '@heroicons/react/20/solid';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label
} from '@oysterjs/ui/v2/fieldset';
import { Button } from '@oysterjs/ui/v2/button';
import { Input } from '@oysterjs/ui/v2/input';
import { Textarea } from '@oysterjs/ui/v2/textarea';
import { Select } from '@oysterjs/ui/v2/select';
import { RadioField } from '../commercial/form';
import {
  BusinessPolicyFieldsFragment,
  ComplianceActionStatus,
  PolicyConversationMessage,
  PolicyConversationMessageRole,
  RiskManagementDataFieldsFragment,
  RiskManagementMarkActionItemCompleteDocument,
  RiskManagementSetActionItemAssigneeDocument,
  RiskManagementSetActionItemDueDateDocument,
  useAddPolicyConversationMessageMutation,
  useGetPolicyConversationQuery,
  useGetRiskManagementQuery
} from '../../types/graphql';
import { Merchant, MerchantUser } from '@oysterjs/types';
import { getMerchantGraphQLClient } from '@oysterjs/core/api/merchant';
import { getDisplayInsuranceType } from '@oysterjs/types/merchant/graphql/map';

export const RiskPage = (props: {
  merchant: Merchant;
  businessPolicies: BusinessPolicyFieldsFragment[];
  riskManagement: RiskManagementDataFieldsFragment | null;
}) => {
  const totalComplianceItems =
    props.riskManagement?.compliance?.reduce((acc, group) => acc + group.items.length, 0) || 1;
  const closedComplianceItems =
    props.riskManagement?.compliance?.reduce(
      (acc, group) =>
        acc +
        group.items.reduce(
          (acc, item) =>
            acc +
            (item.actions.length === 0 ||
            item.actions.find((action) => action.status === ComplianceActionStatus.Open)
              ? 0
              : 1),
          0
        ),
      0
    ) || 0;

  const compliancePercentage = closedComplianceItems / totalComplianceItems;

  const stats = [
    {
      id: 1,
      name: 'Action Items',
      stat: `${props.riskManagement?.actionItems.filter((item) => item.status === ComplianceActionStatus.Open).length} open`,
      icon: BellIcon,
      description: 'Total number of action items'
    },
    {
      id: 2,
      name: 'Compliance',
      stat: new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(compliancePercentage),
      icon: ChartBarIcon,
      description: 'Percentage of compliance'
    },
    {
      id: 3,
      name: 'Potential Risks',
      stat: '4',
      icon: ExclamationTriangleIconOutline,
      description: 'Total number of potential liabilities'
    }
  ];

  const explorablePolicies = props.businessPolicies.filter((p) => !!p.details) || [];

  return (
    <>
      <PageTitle
        title="Risk Management"
        description="Manage and mitigate business risk with Oyster"
      />
      <div className="space-y-8">
        <div className="divide-y divide-neutral-200 rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
              <div>
                <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                  Manage your business risk
                </h3>
                <p className="text-sm text-neutral-500">
                  Monitor and actively mitigate operational risks and compliance requirements.
                </p>
              </div>
              <div className="flex-shrink-0 mt-2 sm:mt-0">
                <Button href="/risk/manage" type="button" color="sky">
                  View Action Items <ArrowRightIcon className="w-4 h-4" />
                </Button>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-col md:flex-row gap-8">
              <div className="flex-1 @container">
                <dl className="grid grid-cols-1 @sm:grid-cols-2 @xl:grid-cols-3 gap-4">
                  {stats.map((item) => (
                    <div key={item.id} className="relative overflow-hidden">
                      <dt>
                        <div className="absolute rounded-md bg-primary-200/50 p-3">
                          <item.icon aria-hidden="true" className="h-6 w-6 text-primary-600" />
                        </div>
                        <p className="ml-16 truncate text-sm font-medium text-neutral-500">
                          {item.name}
                        </p>
                      </dt>
                      <dd className="ml-16">
                        <p className="text-2xl font-semibold text-neutral-900">{item.stat}</p>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
              <div className="inline-flex">
                <div className="grid grid-cols-10 gap-[1px] grid-rows-[auto]">
                  {[...Array(60)].map((_, index) => {
                    const colorClasses: [number, string][] = [
                      [0.3, 'bg-green-300'],
                      [0.45, 'bg-green-200'],
                      [0.65, 'bg-green-100'],
                      [0.85, 'bg-yellow-100'],
                      [0.95, 'bg-yellow-200'],
                      [0.98, 'bg-red-100'],
                      [1.0, 'bg-red-200']
                    ];
                    const randomThreshold = Math.random();
                    const colorClass =
                      colorClasses.find(([threshold]) => randomThreshold <= threshold)?.[1] ||
                      'bg-neutral-300';

                    return (
                      <div
                        key={index}
                        className={`w-8 h-8 ${colorClass} relative group self-start`}
                      >
                        <div className="absolute invisible h-0 bottom-[100%] z-50 left-1/2 transform -translate-x-1/2 bg-neutral-700 text-neutral-50 text-xs rounded py-1 px-2 opacity-0 group-hover:opacity-100 group-hover:visible group-hover:h-auto transition-opacity duration-150 whitespace-nowrap">
                          Risk Factor {index + 1}: {Math.floor(randomThreshold * 100)}% risk
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
              <div>
                <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                  Risk modeling
                </h3>
                <p className="text-sm text-neutral-500">
                  Ensure that your policies would still be adequate for your changing business
                </p>
              </div>
              <div className="flex-shrink-0 mt-2 sm:mt-0">
                <Button href="/risk/model" type="button" color="sky">
                  Start Modeling <ArrowRightIcon className="w-4 h-4" />
                </Button>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-neutral-500">Last Check-in</dt>
                <dd className="mt-1 text-sm text-neutral-900">March 15, 2024</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-neutral-500">Annual Revenue</dt>
                <dd className="mt-1 text-sm text-neutral-900">$1,200,000</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-neutral-500">Primary Operations</dt>
                <dd className="mt-1 text-sm text-neutral-900">Retail, E-Commerce</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-neutral-500">Number of Employees</dt>
                <dd className="mt-1 text-sm text-neutral-900">25</dd>
              </div>
            </dl>
          </div>
        </div>

        {explorablePolicies.length > 0 && (
          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Explore your coverage
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Explore your policies and understand what you're covered for
                  </p>
                </div>
                <div className="flex-shrink-0 mt-2 sm:mt-0">
                  <Button href="/risk/explore" type="button" color="sky">
                    Launch Explorer <ArrowRightIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const getActionItemStatus = (action?: RiskManagementDataFieldsFragment['actionItems'][number]) => {
  let className = 'text-neutral-600 bg-neutral-50 ring-neutral-500/10';
  let title: string | undefined = action?.status;

  if (action?.status === ComplianceActionStatus.Closed) {
    className = 'text-green-700 bg-green-50 ring-green-600/20';
  }

  if (action?.status === ComplianceActionStatus.Open) {
    const dueDate = new Date(action.dueDate).toISOString().split('T')[0];
    const today = new Date().toISOString().split('T')[0];
    if (dueDate < today) {
      title = 'OVERDUE';
      className = 'text-yellow-800 bg-yellow-50 ring-yellow-600/20';
    }
  }

  return (
    <div
      className={clsx(
        className,
        'inline-flex rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
      )}
    >
      {title}
    </div>
  );
};

const ActionItems = (props: RiskDashboardPageProps) => {
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const selectedActionItemId = location.pathname.split('/').pop();
  const selectedActionItem = props.riskManagement?.actionItems.find(
    (item) => item.id === selectedActionItemId
  );

  const updateUser = async (actionId: string, userId: string) => {
    setLoading(true);
    try {
      await getMerchantGraphQLClient().mutate({
        mutation: RiskManagementSetActionItemAssigneeDocument,
        variables: {
          id: actionId,
          userId: userId
        }
      });

      await props.refetchRiskManagement();
    } finally {
      setLoading(false);
    }
  };

  const updateDueDate = async (actionId: string, dueDate: string) => {
    setLoading(true);
    try {
      await getMerchantGraphQLClient().mutate({
        mutation: RiskManagementSetActionItemDueDateDocument,
        variables: {
          id: actionId,
          dueDate: new Date(dueDate).toISOString()
        }
      });

      await props.refetchRiskManagement();
    } finally {
      setLoading(false);
    }
  };

  const markComplete = async (actionId: string) => {
    setLoading(true);
    try {
      await getMerchantGraphQLClient().mutate({
        mutation: RiskManagementMarkActionItemCompleteDocument,
        variables: {
          id: actionId
        }
      });

      await props.refetchRiskManagement();
    } finally {
      setLoading(false);
      setShowConfirmation(false);
      history.push('/risk/manage/actions');
    }
  };

  return (
    <>
      <Dialog
        open={showConfirmation}
        onClose={() => !(loading || props.loadingRiskManagement) && setShowConfirmation(false)}
        className="relative z-50"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-amber-100">
                  <ExclamationTriangleIcon aria-hidden="true" className="h-6 w-6 text-amber-600" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold leading-6 text-neutral-900"
                  >
                    Are you sure this action item is complete?
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text-neutral-500">
                      This action item will be marked as complete and you won't be able to interact
                      with it further. Only mark this as complete if you've verified that the action
                      item has been completed.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 grid grid-flow-row-dense grid-cols-2 gap-3">
                <Button
                  type="button"
                  color="white"
                  disabled={
                    loading ||
                    props.loadingRiskManagement ||
                    selectedActionItem?.status === ComplianceActionStatus.Closed
                  }
                  onClick={() => setShowConfirmation(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  data-autofocus
                  disabled={
                    loading ||
                    props.loadingRiskManagement ||
                    selectedActionItem?.status === ComplianceActionStatus.Closed
                  }
                  onClick={() => markComplete(selectedActionItem?.id || '')}
                  color="sky"
                >
                  Mark as complete
                </Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={!!selectedActionItem}
        onClose={() =>
          !(loading || props.loadingRiskManagement) && history.push('/risk/manage/actions')
        }
        className="relative z-40"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-all duration-300 data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-full sm:max-w-lg transform transition-all duration-300 data-[closed]:translate-x-full"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-medium font-sans m-0 text-neutral-900">
                        View action item
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => history.push('/risk/manage/actions')}
                          className="relative rounded-md bg-white text-neutral-400 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <h2 className="text-xl font-semibold mt-0 text-neutral-900">
                      {selectedActionItem?.title}
                    </h2>
                    {/* Compliance Item */}
                    {!!selectedActionItem?.complianceItems?.length && (
                      <div className="flex bg-primary-50/75 p-4 mt-3 rounded-md">
                        <div className="flex-shrink-0">
                          <LinkIcon className="h-5 w-5 text-primary-800" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm mb-2 font-medium text-primary-800">
                            Linked to compliance items
                          </p>
                          {selectedActionItem?.complianceItems.map((item) => (
                            <NavLink
                              key={item.id}
                              to={`/risk/manage/compliance/${item.id}`}
                              className="text-sm block mt-1 text-primary-600 hover:text-primary-900 cursor-pointer"
                            >
                              {item.title}
                              <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 inline-block align-text-top" />
                            </NavLink>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="rounded-md mt-4 border-b border-t border-neutral-100">
                      <dl className="divide-y divide-neutral-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-neutral-900 sm:col-span-3">
                            Status
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-5 sm:mt-0">
                            {getActionItemStatus(selectedActionItem)}
                          </dd>
                        </div>
                        <div className="px-4 py-4 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-neutral-900 sm:col-span-3">
                            Assignee
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-5 sm:mt-0">
                            <Select
                              defaultValue={selectedActionItem?.assignee?.id}
                              disabled={
                                loading ||
                                props.loadingRiskManagement ||
                                selectedActionItem?.status === ComplianceActionStatus.Closed
                              }
                              onChange={(e) =>
                                updateUser(selectedActionItem?.id || '', e.currentTarget.value)
                              }
                            >
                              <option value="">Unassigned</option>
                              {props.users.map((user) => (
                                <option key={user.ID} value={user.ID}>
                                  {user.FirstName} {user.LastName}
                                </option>
                              ))}
                            </Select>
                          </dd>
                        </div>
                        <div className="px-4 py-4 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-neutral-900 sm:col-span-3">
                            Due Date
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-5 sm:mt-0">
                            <Input
                              type="date"
                              defaultValue={
                                selectedActionItem?.dueDate
                                  ? new Date(selectedActionItem.dueDate).toISOString().split('T')[0]
                                  : ''
                              }
                              disabled={
                                loading ||
                                props.loadingRiskManagement ||
                                selectedActionItem?.status === ComplianceActionStatus.Closed
                              }
                              onBlur={(e) =>
                                updateDueDate(selectedActionItem?.id || '', e.target.value)
                              }
                            />
                          </dd>
                        </div>
                        <div className="px-4 py-4 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-neutral-900 sm:col-span-3">
                            Description
                          </dt>
                          <dd className="mt-1 text-sm leading-6 text-neutral-700 sm:col-span-7 sm:mt-0">
                            {selectedActionItem?.description}
                          </dd>
                        </div>
                        <div className="px-4 py-4 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0">
                          <dt className="text-sm font-medium leading-6 text-neutral-900 sm:col-span-3">
                            How to complete
                          </dt>
                          <dd className="mt-1 text-neutral-700 sm:col-span-7 sm:mt-0 flex flex-col gap-2">
                            {selectedActionItem?.completionRequirements?.map((requirement) => (
                              <div key={requirement.title}>
                                <p className="text-sm leading-6">{requirement.title}</p>
                                <p className="text-xs text-neutral-500">
                                  {requirement.description}
                                </p>
                              </div>
                            ))}
                          </dd>
                        </div>
                      </dl>
                    </div>

                    <Button
                      type="button"
                      color="sky"
                      className="mt-4"
                      disabled={
                        loading ||
                        props.loadingRiskManagement ||
                        selectedActionItem?.status === ComplianceActionStatus.Closed
                      }
                      onClick={() => setShowConfirmation(true)}
                    >
                      Mark as complete
                    </Button>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>

      <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div>
              <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                Action Items
              </h3>
              <p className="text-sm text-neutral-500">
                Recommended actions to take to ensure your business is compliant and protected.
              </p>
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-neutral-200">
                <thead className="bg-neutral-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-neutral-900 sm:pl-6 lg:pl-8"
                    >
                      Action Item
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Assignee
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Due Date
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-neutral-200 bg-white">
                  {[...(props.riskManagement?.actionItems || [])]
                    .sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime())
                    .map((action) => (
                      <tr key={action.id}>
                        <td className="py-4 pl-4 pr-3 sm:pl-6 lg:pl-8">
                          <div className="text-sm font-medium text-neutral-900">{action.title}</div>
                          {/* <div className="text-xs mt-1 text-neutral-500"></div> */}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm">
                          {getActionItemStatus(action)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm flex-inline items-center gap-2">
                          {action.assignee ? (
                            <div className="flex items-center gap-2">
                              <span className="rounded-full h-7 w-7 bg-primary-200/50 text-primary-600 flex items-center justify-center font-medium text-xs">
                                {action.assignee.name.split(' ')[0][0].toUpperCase()}
                                {action.assignee.name.split(' ')[1][0].toUpperCase()}
                              </span>
                              <span className="text-neutral-500">{action.assignee.name}</span>
                            </div>
                          ) : (
                            <span className="text-neutral-500 italic">Unassigned</span>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {!isNaN(new Date(action.dueDate).getTime()) ? (
                            new Intl.DateTimeFormat('en-US', {
                              month: 'long',
                              day: 'numeric',
                              year: 'numeric',
                              timeZone: 'UTC'
                            }).format(new Date(action.dueDate))
                          ) : (
                            <span className="text-neutral-500 italic">No due date</span>
                          )}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <NavLink
                            to={`/risk/manage/actions/${action.id}`}
                            className="text-primary-600 hover:text-primary-900 cursor-pointer"
                          >
                            View
                            <span className="sr-only">, {action.title}</span>
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ComplianceItems = (props: RiskDashboardPageProps) => {
  const history = useHistory();
  const location = useLocation();

  const selectedComplianceItemId = location.pathname.split('/').pop();
  const selectedComplianceItem = props.riskManagement?.compliance
    ?.flatMap((group) => [...group.items])
    .find((item) => item.id === selectedComplianceItemId);

  return (
    <>
      <Dialog
        open={!!selectedComplianceItem}
        onClose={() => history.push('/risk/manage/compliance')}
        className="relative z-40"
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-all duration-300 data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-full sm:max-w-lg transform transition-all duration-300 data-[closed]:translate-x-full"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className="text-base font-medium font-sans m-0 text-neutral-900">
                        View compliance item
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => history.push('/risk/manage/compliance')}
                          className="relative rounded-md bg-white text-neutral-400 hover:text-neutral-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <h2 className="text-lg/6 font-semibold mt-0 text-neutral-900">
                      {selectedComplianceItem?.title}
                    </h2>
                    <div className="mt-4 space-y-6">
                      <div>
                        <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">
                          Description
                        </h3>
                        <p className="text-sm text-neutral-500">
                          {selectedComplianceItem?.description}
                        </p>
                      </div>

                      <div>
                        <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">
                          Standards
                        </h3>
                        <div className="text-sm text-neutral-500 flex flex-col">
                          {selectedComplianceItem?.standards?.map((standard) => (
                            <a
                              href={standard.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primary-600 hover:text-primary-900"
                            >
                              {standard.title}
                              <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 inline-block align-text-top" />
                            </a>
                          ))}
                        </div>
                      </div>

                      <div>
                        <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">
                          Reference Materials
                        </h3>
                        <div className="text-sm text-neutral-500 flex flex-col">
                          {selectedComplianceItem?.referenceMaterials?.map((material) => (
                            <a
                              href={material.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-primary-600 hover:text-primary-900"
                            >
                              {material.title}
                              <ArrowTopRightOnSquareIcon className="h-4 w-4 ml-1 inline-block align-text-top" />
                            </a>
                          ))}
                        </div>
                      </div>

                      {!selectedComplianceItem?.actions.length && (
                        <div>
                          <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">
                            Requirements
                          </h3>
                          <div className="text-sm text-neutral-500 flex flex-col">
                            {selectedComplianceItem?.requirements?.map((requirement) => (
                              <span key={requirement.title}>{requirement.title}</span>
                            ))}
                          </div>
                        </div>
                      )}

                      {!!selectedComplianceItem?.actions.length && (
                        <div>
                          <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">
                            Progress
                          </h3>
                          <p className="text-sm text-neutral-500">
                            {
                              selectedComplianceItem?.actions.filter(
                                (a) => a.status === ComplianceActionStatus.Closed
                              ).length
                            }{' '}
                            out of {selectedComplianceItem?.actions.length} linked action items
                            completed.
                          </p>
                          <div className="h-2 mt-2 w-full rounded-full bg-neutral-100 dark:bg-neutral-700">
                            <div
                              className={`h-2 rounded-full bg-gradient-to-r to-primary-300 from-primary-600 bg-[size:200%_100%]`}
                              style={{
                                width: `${
                                  (selectedComplianceItem?.actions.filter(
                                    (a) => a.status === ComplianceActionStatus.Closed
                                  ).length /
                                    selectedComplianceItem?.actions.length) *
                                  100
                                }%`
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {!!selectedComplianceItem?.actions.length && (
                        <div>
                          <h3 className="text-sm font-medium font-sans mt-0 text-neutral-900">
                            Linked Action Items
                          </h3>
                          <ul role="list" className="divide-y divide-gray-100">
                            {selectedComplianceItem?.actions.map((action) => (
                              <li key={action.id} className="flex justify-between gap-x-6 py-3">
                                <div className="min-w-0">
                                  <div className="flex items-center gap-x-2">
                                    {action.status === ComplianceActionStatus.Closed ? (
                                      <CheckCircleIcon className="h-4 w-4 text-primary-500" />
                                    ) : (
                                      <div className="h-4 w-4 inline rounded-full border border-neutral-300" />
                                    )}
                                    <NavLink
                                      to={`/risk/manage/actions/${action.id}`}
                                      className="text-sm leading-6 text-gray-900 hover:text-primary-900 cursor-pointer"
                                    >
                                      {action.title}
                                    </NavLink>
                                  </div>
                                  <div className="mt-1 ml-6 flex items-center gap-x-2 leading-5 text-gray-500">
                                    <p className="whitespace-nowrap text-xs">
                                      Due on{' '}
                                      <time dateTime={new Date(action.dueDate).toISOString()}>
                                        {new Intl.DateTimeFormat('en-US', {
                                          month: 'long',
                                          day: 'numeric',
                                          year: 'numeric',
                                          timeZone: 'UTC'
                                        }).format(new Date(action.dueDate))}
                                      </time>
                                    </p>
                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                      <circle r={1} cx={1} cy={1} />
                                    </svg>
                                    {!!action.assignee && (
                                      <p className="truncate text-xs">
                                        Assigned to {action.assignee?.name}
                                      </p>
                                    )}
                                    {!action.assignee && (
                                      <p className="truncate text-xs">Unassigned</p>
                                    )}
                                  </div>
                                </div>
                                <div className="flex items-start gap-x-2">
                                  <NavLink
                                    to={`/risk/manage/actions/${action.id}`}
                                    className="text-primary-600 hover:text-primary-900 cursor-pointer"
                                  >
                                    <EyeIcon className="h-5 w-5 mt-1" />
                                    <span className="sr-only">View action item</span>
                                  </NavLink>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>

      <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div>
              <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                Compliance Requirements
              </h3>
              <p className="text-sm text-neutral-500">
                Based on your business type and insurance policies, here are the compliance
                requirements you should consider.
              </p>
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full">
                <thead>
                  <th
                    scope="col"
                    className="pl-4 pr-3 py-0 text-left text-sm font-semibold text-neutral-900 sm:pl-6 lg:pl-8"
                  >
                    <span className="sr-only">Completed</span>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-0 text-left text-sm font-semibold text-neutral-900"
                  >
                    <span className="sr-only">Compliance Requirement</span>
                  </th>
                  <th scope="col" className="relative pl-3 py-0 pr-4 sm:pr-6 lg:pr-8">
                    <span className="sr-only">View</span>
                  </th>
                </thead>
                <tbody className="divide-y divide-neutral-200 bg-white">
                  {props.riskManagement?.compliance?.map((category) => (
                    <React.Fragment key={category.slug}>
                      <tr className="border-t first:border-t-0 border-neutral-200">
                        <th className="bg-neutral-50" />
                        <th
                          colSpan={2}
                          scope="colgroup"
                          className="bg-neutral-50 py-3.5 pl-3 text-left text-sm font-semibold text-neutral-900"
                        >
                          {category.title}
                        </th>
                      </tr>
                      {category.items.map((item) => (
                        <tr key={item.id}>
                          <td className="py-4 pl-4 pr-3 sm:pl-6 lg:pl-8">
                            {item.actions.length > 0 &&
                              !item.actions.find(
                                (a) => a.status === ComplianceActionStatus.Open
                              ) && <CheckCircleIcon className="h-5 w-5 text-primary-500" />}
                          </td>
                          <td className="px-3 py-4 text-sm">{item.title}</td>
                          <td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <NavLink
                              to={`/risk/manage/compliance/${item.id}`}
                              className="text-primary-600 hover:text-primary-900 cursor-pointer"
                            >
                              View<span className="sr-only">, {item.title}</span>
                            </NavLink>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface PageNavigationRoute {
  path: string;
  title: string;
  count?: number;
  exact?: boolean;
  absolute?: boolean;
  render: () => JSX.Element;
}

interface PageNavigationProps {
  routes: PageNavigationRoute[];
  redirect?: Record<string, string>;
}

export const PageNavigation = (props: React.PropsWithChildren<PageNavigationProps>) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();
  const concatPath = (base: string, relative: string) =>
    `${base.replace(/\/+$/, '')}/${relative.replace(/\/+$/, '').replace(/^\/+/, '')}`;

  return (
    <div className="-mt-6">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          defaultValue={
            props.routes.find((tab) => window.location.pathname.includes(concatPath(url, tab.path)))
              ?.path
          }
          onChange={(e) => history.push(concatPath(url, e.currentTarget.value))}
          className="block w-full rounded-md border-neutral-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
        >
          {props.routes.map((tab) => (
            <option key={tab.path}>{tab.title}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-neutral-200">
          <nav aria-label="Tabs" className="-mb-px flex space-x-8">
            {props.routes.map((tab) => (
              <NavLink
                key={tab.path}
                to={concatPath(path, tab.path)}
                aria-current={
                  window.location.pathname.includes(concatPath(url, tab.path)) ? 'page' : undefined
                }
                className={clsx(
                  window.location.pathname.includes(concatPath(url, tab.path))
                    ? 'border-primary-500 text-primary-600'
                    : 'border-transparent text-neutral-500 hover:border-neutral-200 hover:text-neutral-700',
                  'flex whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                )}
              >
                {tab.title}
                {tab.count ? (
                  <span
                    className={clsx(
                      window.location.pathname.includes(concatPath(url, tab.path))
                        ? 'bg-primary-200/65 text-primary-600'
                        : 'bg-neutral-200 text-neutral-900',
                      'ml-3 hidden rounded-full px-2.5 py-0.5 text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div className="mt-8">
        <Switch>
          {props.routes.map((route) => (
            <Route
              exact={route.exact}
              key={route.path}
              path={concatPath(path, route.path)}
              render={route.render}
            />
          ))}
          {Object.entries(props.redirect || {}).map(([from, to]) => (
            <Redirect
              exact
              key={from + to}
              from={concatPath(path, from)}
              to={concatPath(path, to)}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
};

interface RiskDashboardPageProps {
  users: MerchantUser[];
  riskManagement: RiskManagementDataFieldsFragment | null;
  loadingRiskManagement: boolean;
  refetchRiskManagement: () => Promise<unknown>;
}

export const RiskDashboardPage = (props: {
  merchant: Merchant;
  users: MerchantUser[];
  businessPolicies: BusinessPolicyFieldsFragment[];
  riskManagement: RiskManagementDataFieldsFragment | null;
}) => {
  const { data, loading, refetch } = useGetRiskManagementQuery();

  return (
    <PageTitle
      title="Risk Dashboard"
      description="View your compliance requirements and risk-reduction action items."
    >
      <PageNavigation
        redirect={{ '/': '/actions' }}
        routes={[
          {
            path: '/actions',
            title: 'Action Items',
            render: () => (
              <ActionItems
                users={props.users}
                riskManagement={data?.riskManagement || props.riskManagement}
                loadingRiskManagement={loading}
                refetchRiskManagement={refetch}
              />
            ),
            count:
              props.riskManagement?.actionItems?.filter(
                (item) => item.status === ComplianceActionStatus.Open
              )?.length || 0
          },
          {
            path: '/compliance',
            title: 'Compliance',
            render: () => (
              <ComplianceItems
                users={props.users}
                riskManagement={data?.riskManagement || props.riskManagement}
                loadingRiskManagement={loading}
                refetchRiskManagement={refetch}
              />
            ),
            count:
              props.riskManagement?.compliance?.reduce(
                (acc, group) => acc + group.items.length,
                0
              ) || 0
          }
        ]}
      />
    </PageTitle>
  );
};

const ModelRiskFieldNote = (props: {
  original: string;
  changeAmount: string;
  positive: boolean;
}) => (
  <div className="mt-1 text-xs">
    {props.positive ? (
      <>
        <span className="font-medium text-green-600">{props.changeAmount} increase</span>{' '}
        <span className="text-neutral-500">from {props.original}</span>
      </>
    ) : (
      <>
        <span className="font-medium text-red-600">{props.changeAmount} decrease</span>{' '}
        <span className="text-neutral-500">from {props.original}</span>
      </>
    )}
  </div>
);

export const ModelRiskPage = () => {
  const ref = React.useRef<HTMLFormElement>(null);
  const [showRiskModel, setShowRiskModel] = React.useState(false);

  const [form, setForm] = React.useState({
    annualRevenue: '120000',
    totalPayroll: '60000',
    primaryOperations: 'Retail, E-Commerce',
    productTypes: 'Bikes, Apparel, Accessories',
    additionalOperations: 'Bike Rentals, Bike Repairs, Bike Sales'
  });

  return (
    <>
      <PageTitle
        title="Business Risk Modeling"
        description="Use Oyster to model how your insurance and risk requirements change as your business grows. Fill out the form to get updated recommendations for your business."
      />
      {showRiskModel && (
        <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
              <div>
                <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                  Your risk results
                </h3>
                <p className="text-sm text-neutral-500">
                  Based on your information, here are our risk and insurance recommendations.
                </p>
              </div>
            </div>
          </div>
          <div className="px-4 py-5 sm:px-6">
            <div className="space-y-6">
              <div className="space-y-4">
                <div className="flex items-center justify-between p-4 bg-green-50 rounded-lg">
                  <div>
                    <h5 className="font-medium text-green-800">Cyber Insurance</h5>
                    <p className="text-xs text-green-700">
                      Coverage is adequate. No changes needed.
                    </p>
                  </div>
                  <CheckCircleIcon className="h-6 w-6 text-green-500" />
                </div>

                <div className="flex items-center justify-between p-4 bg-yellow-50 rounded-lg">
                  <div>
                    <h5 className="font-medium text-yellow-800">General Liability</h5>
                    <p className="text-xs text-yellow-700">
                      Risk exposure exceeds policy limits. We recommend increasing your coverage.
                    </p>
                    <p className="text-xs font-medium text-yellow-700">
                      Estimated additional cost: $400/year
                    </p>
                  </div>
                  <XCircleIcon className="h-6 w-6 text-yellow-500" />
                </div>

                <div className="flex items-center justify-between p-4 bg-yellow-50 rounded-lg">
                  <div>
                    <h5 className="font-medium text-yellow-800">Workers Compensation</h5>
                    <p className="text-xs text-yellow-700">
                      Risk exposure exceeds policy limits. We recommend increasing your coverage.
                    </p>
                    <p className="text-xs font-medium text-yellow-700">
                      Estimated additional cost: $1,600/year
                    </p>
                  </div>
                  <XCircleIcon className="h-6 w-6 text-yellow-500" />
                </div>

                <div className="flex items-center justify-between p-4 bg-blue-50 rounded-lg">
                  <div>
                    <h5 className="font-medium text-blue-800">Rental Insurance</h5>
                    <p className="text-xs text-blue-700">
                      Rentals are not covered by your existing insurance. We recommended a rental
                      insurance policy.
                    </p>
                    <p className="text-xs font-medium text-blue-700">
                      Estimated cost: $500 - $1,500/year
                    </p>
                  </div>
                  <CursorArrowRaysIcon className="h-6 w-6 text-blue-500" />
                </div>
              </div>

              <div className="flex gap-4 mt-6">
                <Button type="button" color="white" onClick={() => setShowRiskModel(false)}>
                  Go Back
                </Button>
                <Button type="button" color="sky">
                  Contact your agent
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!showRiskModel && (
        <form
          className="space-y-8"
          ref={ref}
          onSubmit={(e) => {
            e.preventDefault();
            setShowRiskModel(true);
          }}
        >
          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Business Size
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Update business size information to see how your insurance and risk requirements
                    change.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <Fieldset>
                <FieldGroup>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                    <Field>
                      <Label>Annual Revenue</Label>
                      <Description>
                        The total annual revenue generated by your business.
                      </Description>
                      <Input
                        name="annualRevenue"
                        type="number"
                        value={form.annualRevenue}
                        onChange={(e) => setForm({ ...form, annualRevenue: e.target.value })}
                      />
                      {!isNaN(parseFloat(form.annualRevenue)) &&
                        parseFloat(form.annualRevenue) !== 120000 && (
                          <ModelRiskFieldNote
                            original="$120,000"
                            changeAmount={`${new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                              style: 'currency',
                              currency: 'USD'
                            }).format(Math.abs(parseFloat(form.annualRevenue) - 120000))}`}
                            positive={parseFloat(form.annualRevenue) > 120000}
                          />
                        )}
                    </Field>
                    <Field>
                      <Label>Total Payroll</Label>
                      <Description>
                        The total annual employee payroll for your business.
                      </Description>
                      <Input
                        type="number"
                        value={form.totalPayroll}
                        onChange={(e) => setForm({ ...form, totalPayroll: e.target.value })}
                      />
                      {!isNaN(parseFloat(form.totalPayroll)) &&
                        parseFloat(form.totalPayroll) !== 60000 && (
                          <ModelRiskFieldNote
                            original="$60,000"
                            changeAmount={`${new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2,
                              style: 'currency',
                              currency: 'USD'
                            }).format(Math.abs(parseFloat(form.totalPayroll) - 60000))}`}
                            positive={parseFloat(form.totalPayroll) > 60000}
                          />
                        )}
                    </Field>
                  </div>
                </FieldGroup>
              </Fieldset>
            </div>
          </div>

          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Business Operations
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Update business operations information to see how your insurance and risk
                    requirements change.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <Fieldset>
                <FieldGroup>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                    <Field>
                      <Label>Primary Operations</Label>
                      <Description>The primary operations of your business.</Description>
                      <Input
                        type="text"
                        value={form.primaryOperations}
                        onChange={(e) => setForm({ ...form, primaryOperations: e.target.value })}
                      />
                    </Field>
                    <Field>
                      <Label>Product Types</Label>
                      <Description>The types of products you sell.</Description>
                      <Input
                        type="text"
                        value={form.productTypes}
                        onChange={(e) => setForm({ ...form, productTypes: e.target.value })}
                      />
                    </Field>
                  </div>
                  <Field>
                    <Label>Additional Operations</Label>
                    <Description>Describe any additional operations of your business.</Description>
                    <Textarea
                      rows={4}
                      resizable={false}
                      value={form.additionalOperations}
                      onChange={(e) => setForm({ ...form, additionalOperations: e.target.value })}
                    />
                  </Field>
                </FieldGroup>
              </Fieldset>
            </div>
          </div>

          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Business Risk Factors
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Answer a few questions about your business' risk factors to see how your
                    insurance and risk requirements change.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <Fieldset>
                <FieldGroup>
                  <RadioField
                    onChange={() => {}}
                    label="Do you manufacture using your own brand?"
                    options={[
                      { value: 'YES', displayText: 'Yes' },
                      { value: 'NO', displayText: 'No' }
                    ]}
                  />
                  <RadioField
                    onChange={() => {}}
                    label="Will bike rentals exceed 20% of your annual sales?"
                    options={[
                      { value: 'YES', displayText: 'Yes' },
                      { value: 'NO', displayText: 'No' }
                    ]}
                  />
                  <RadioField
                    onChange={() => {}}
                    label="Do you have a legally-reviewed rental liability release form?"
                    options={[
                      { value: 'YES', displayText: 'Yes' },
                      { value: 'NO', displayText: 'No' }
                    ]}
                  />
                </FieldGroup>
              </Fieldset>
            </div>
          </div>
          <Button color="sky" type="submit">
            Model my risk
          </Button>
        </form>
      )}
    </>
  );
};

export const ExploreRiskPage = (props: { businessPolicies: BusinessPolicyFieldsFragment[] }) => {
  const [selectedPolicy, setSelectedPolicy] = React.useState<BusinessPolicyFieldsFragment | null>(
    props.businessPolicies[0] || null
  );
  const [showPolicyQuotes, setShowPolicyQuotes] = React.useState<Record<string, boolean>>({});

  if (!selectedPolicy) {
    return (
      <div className="text-center">
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="mx-auto h-12 w-12 text-neutral-400"
        >
          <path
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            strokeWidth={2}
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-neutral-900">No policies</h3>
        <p className="mt-1 text-sm text-neutral-500">
          You don't have any policies to explore. Get started by applying for one.
        </p>
        <div className="mt-6 flex gap-2 items-center justify-center">
          <Button type="button" color="white">
            Go back
          </Button>
          <Button type="button" color="sky">
            Apply for a policy
          </Button>
        </div>
      </div>
    );
  }

  const policyStats = [
    {
      name: 'Premium',
      value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        selectedPolicy.pricing.premium || 0
      )
    },
    {
      name: 'Deductible',
      value: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
        selectedPolicy.details?.deductible || 0
      )
    },
    {
      name: 'Expiration',
      value: new Date(selectedPolicy.expiresAt || '').toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })
    }
  ];

  const policyLimits = [
    { name: 'aggregate', limit: selectedPolicy.details?.limits?.cyberAggregateLimit },
    { name: 'retention', limit: selectedPolicy.details?.limits?.cyberRetentionLimit },
    { name: 'aggregate', limit: selectedPolicy.details?.limits?.glAggregateLimit },
    {
      name: 'medical per occurrence',
      limit: selectedPolicy.details?.limits?.glMedicalPerOccurrenceLimit
    },
    {
      name: 'medical per person',
      limit: selectedPolicy.details?.limits?.glMedicalPerPersonLimit
    },
    { name: 'per occurrence', limit: selectedPolicy.details?.limits?.glPerOccurrenceLimit },
    {
      name: 'personal and advertising injury',
      limit: selectedPolicy.details?.limits?.glPersonalAndAdvertisingInjuryLimit
    },
    {
      name: 'products and completed operations',
      limit: selectedPolicy.details?.limits?.glProductsAndCompletedOperationsLimit
    },
    {
      name: 'rented premises damage',
      limit: selectedPolicy.details?.limits?.glRentedPremisesDamageLimit
    },
    { name: 'building', limit: selectedPolicy.details?.limits?.propertyBuildingLimit },
    { name: 'contents', limit: selectedPolicy.details?.limits?.propertyContentsLimit },
    { name: 'per accident', limit: selectedPolicy.details?.limits?.wcPerAccidentLimit },
    {
      name: 'per disease (employee)',
      limit: selectedPolicy.details?.limits?.wcPerDiseaseEmployeeLimit
    },
    {
      name: 'per disease (policy)',
      limit: selectedPolicy.details?.limits?.wcPerDiseasePolicyLimit
    },
    ...(selectedPolicy.details?.extraLimits || [])
  ].filter((limit) => !!limit?.limit);

  return (
    <>
      <PageTitle
        title="Explore your coverage"
        description="Explore your policies and understand what you're covered for. Make sure you speak with an agent before acting on any information here."
      />
      <form className="sm:max-w-md" onSubmit={(e) => e.preventDefault()}>
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>Choose a policy</Label>
              <Description>Select the policy you want to explore.</Description>
              <Select
                value={selectedPolicy.id}
                onChange={(e) => {
                  setSelectedPolicy(
                    props.businessPolicies.find((p) => p.id === e.currentTarget.value) || null
                  );
                }}
              >
                {props.businessPolicies.map((policy) => (
                  <option key={policy.id} value={policy.id}>
                    {getDisplayInsuranceType(policy.type)} ({policy.policyNumber})
                  </option>
                ))}
              </Select>
            </Field>
          </FieldGroup>
        </Fieldset>
      </form>
      <div className="mt-8 w-full grow xl:flex">
        <div className="flex-1 space-y-8 pb-8 w-full xl:pb-0 xl:pr-4 xl:flex-1">
          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Policy overview
                  </h3>
                  <p className="text-sm text-neutral-500">Basic details about your policy.</p>
                </div>
              </div>
            </div>
            <div className="@container">
              <dl className="px-4 py-5 sm:px-6 mx-auto grid grid-cols-1 gap-8 @sm:grid-cols-2 @lg:grid-cols-3">
                {policyStats.map((stat) => (
                  <div
                    key={stat.name}
                    className="flex flex-wrap items-baseline justify-between bg-white"
                  >
                    <dt className="text-sm font-medium text-neutral-500">{stat.name}</dt>
                    <dd className="w-full flex-none text-xl font-medium leading-10 tracking-tight text-neutral-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
              <dl className="divide-y divide-neutral-100 border-t border-neutral-100 pb-2">
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Insured Entity</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    <span className="font-medium">
                      {selectedPolicy.details?.insured?.name || 'Unknown'}
                    </span>
                    <br />
                    <span>{selectedPolicy.details?.insured?.address?.line1 || 'Unknown'}</span>
                    <br />
                    <span>
                      {selectedPolicy.details?.insured?.address?.city || 'Unknown'},{' '}
                      {selectedPolicy.details?.insured?.address?.zone || 'Unknown'}{' '}
                      {selectedPolicy.details?.insured?.address?.postalCode || 'Unknown'}
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Carrier</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    <span className="font-medium">
                      {selectedPolicy.details?.carrier?.name || 'Unknown'}
                    </span>
                    <br />
                    <span>{selectedPolicy.details?.carrier?.address?.line1 || 'Unknown'}</span>
                    <br />
                    <span>
                      {selectedPolicy.details?.carrier?.address?.city || 'Unknown'},{' '}
                      {selectedPolicy.details?.carrier?.address?.zone || 'Unknown'}{' '}
                      {selectedPolicy.details?.carrier?.address?.postalCode || 'Unknown'}
                    </span>
                  </dd>
                </div>
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Policy Summary</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    {selectedPolicy.details?.summary || 'Unknown'}
                  </dd>
                </div>
                <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500">Limits</dt>
                  <dd className="mt-1 text-sm leading-6 text-neutral-900 sm:col-span-2 sm:mt-0">
                    {policyLimits.map((limit) => (
                      <div key={limit.name}>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                          currency: 'USD'
                        }).format(limit.limit || 0)}{' '}
                        {limit.name}
                      </div>
                    ))}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    What's covered
                  </h3>
                  <p className="text-sm text-neutral-500">Coverage for your business.</p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-col gap-2">
                <dl className="grid grid-cols-1 gap-4">
                  {selectedPolicy.details?.coverageDescriptions?.map((coverage) => (
                    <div key={coverage.title}>
                      <dt className="text-sm flex items-center gap-2">
                        <CheckCircleIcon className="h-4 w-4 text-emerald-500" aria-hidden="true" />
                        <span className="font-medium text-neutral-500">{coverage.title}</span>
                      </dt>
                      <dd className="text-sm text-neutral-900 ml-6 mt-1 leading-5">
                        {coverage.description}
                      </dd>
                      {coverage.policyQuotes.length > 0 && !showPolicyQuotes[coverage.title] && (
                        <HeadlessButton
                          onClick={() =>
                            setShowPolicyQuotes({
                              ...showPolicyQuotes,
                              [coverage.title]: true
                            })
                          }
                          className="text-xs font-medium text-primary-500 hover:text-primary-800 inline-flex items-center gap-1 ml-6"
                        >
                          Show relevant quotes <ArrowRightIcon className="h-3 w-3" />
                        </HeadlessButton>
                      )}
                      {coverage.policyQuotes.length > 0 && showPolicyQuotes[coverage.title] && (
                        <div className="flex flex-col gap-2 mt-2 ml-6">
                          {coverage.policyQuotes.map((quote) => (
                            <div
                              key={quote}
                              className="text-xs text-neutral-500 border-l-4 border-neutral-200 px-2 py-0.5"
                            >
                              {quote}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </dl>
                <p className="text-xs text-neutral-400 mt-2">
                  The coverage descriptions provided here may not be complete, and may have
                  important exclusions or limitations. For a full understanding of your coverage,
                  please read your policy documents or speak with an insurance agent.
                </p>
              </div>
            </div>
          </div>

          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    What's not covered
                  </h3>
                  <p className="text-sm text-neutral-500">
                    Exclusions and other limitations on your policy.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-col gap-2">
                <dl className="grid grid-cols-1 gap-4">
                  {selectedPolicy.details?.exclusionDescriptions?.map((exclusion) => (
                    <div key={exclusion.title}>
                      <dt className="text-sm flex items-center gap-2">
                        <XCircleIcon className="h-4 w-4 text-red-500" aria-hidden="true" />
                        <span className="font-medium text-neutral-500">{exclusion.title}</span>
                      </dt>
                      <dd className="text-sm text-neutral-900 ml-6 mt-1 leading-5">
                        {exclusion.description}
                      </dd>
                      {exclusion.policyQuotes.length > 0 && !showPolicyQuotes[exclusion.title] && (
                        <HeadlessButton
                          onClick={() =>
                            setShowPolicyQuotes({
                              ...showPolicyQuotes,
                              [exclusion.title]: true
                            })
                          }
                          className="text-xs font-medium text-primary-500 hover:text-primary-800 inline-flex items-center gap-1 ml-6"
                        >
                          Show relevant quotes <ArrowRightIcon className="h-3 w-3" />
                        </HeadlessButton>
                      )}
                      {exclusion.policyQuotes.length > 0 && showPolicyQuotes[exclusion.title] && (
                        <div className="flex flex-col gap-2 mt-2 ml-6">
                          {exclusion.policyQuotes.map((quote) => (
                            <div
                              key={quote}
                              className="text-xs text-neutral-500 border-l-4 border-neutral-200 px-2 py-0.5"
                            >
                              {quote}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </dl>
                <p className="text-xs text-neutral-400 mt-2">
                  The exclusions provided here may not be complete, and may have important caveats
                  or conditions. For a full understanding of your policy, please read your policy
                  documents or speak with an insurance agent.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="shrink-0 border-neutral-200 w-full space-y-8 xl:pl-4 xl:w-[25rem]">
          <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex flex-wrap gap-2 items-center justify-between sm:flex-nowrap sm:gap-4">
                <div>
                  <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                    Ask a question
                  </h3>
                  <p className="text-sm text-neutral-500">
                    We'll find related information in your policy.
                  </p>
                </div>
              </div>
            </div>
            <div className="px-4 py-5 sm:px-6">
              <PolicyChat policyId={selectedPolicy.id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PolicyChat = (props: { policyId: string }) => {
  // Load the initial conversation messages
  const {
    data: initialData,
    loading: initialLoading,
    refetch: refetchInitialData
  } = useGetPolicyConversationQuery({
    variables: { policyId: props.policyId }
  });

  // Send a message to the policy chat
  const [
    sendMessage,
    { data: sendMessageData, loading: sendMessageLoading, error: sendMessageError }
  ] = useAddPolicyConversationMessageMutation();

  // State for the chat
  const [editorText, setEditorText] = React.useState('');
  const [messages, setMessages] = React.useState<PolicyConversationMessage[]>([]);
  const [stagedMessage, setStagedMessage] = React.useState('');
  const [showAttachments, setShowAttachments] = React.useState<Record<string, boolean>>({});

  React.useEffect(() => {
    refetchInitialData({ policyId: props.policyId });
  }, [props.policyId]);

  React.useEffect(() => {
    if (initialData) {
      setMessages(initialData.policyConversation);
    }
  }, [initialData]);

  React.useEffect(() => {
    if (sendMessageData) {
      setMessages(sendMessageData.riskManagementAddPolicyConversationMessage);
    }
  }, [sendMessageData?.riskManagementAddPolicyConversationMessage]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setEditorText('');
    setStagedMessage(editorText);
    await sendMessage({
      variables: { policyId: props.policyId, message: editorText }
    });
    setStagedMessage('');
  };

  if (initialLoading) {
    return (
      <div className="space-y-4 animate-pulse">
        <div className="flex justify-start">
          <div className="rounded-lg p-3 w-full max-w-[80%] bg-neutral-100">
            <div className="h-3 w-3/4 bg-neutral-300 rounded-full" />
            <div className="h-3 w-1/2 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-5/6 bg-neutral-300 rounded-full mt-2" />
          </div>
        </div>
        <div className="flex justify-end">
          <div className="rounded-lg p-3 w-full max-w-[75%] bg-primary-100">
            <div className="h-3 w-3/4 bg-primary-300 rounded-full" />
            <div className="h-3 w-1/2 bg-primary-300 rounded-full mt-2" />
          </div>
        </div>
        <div className="flex justify-start">
          <div className="rounded-lg p-3 w-full max-w-[80%] bg-neutral-100">
            <div className="h-3 w-3/4 bg-neutral-300 rounded-full" />
            <div className="h-3 w-2/3 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-5/6 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-1/2 bg-neutral-300 rounded-full mt-2" />
            <div className="h-3 w-3/4 bg-neutral-300 rounded-full mt-2" />
          </div>
        </div>
        <div className="flex">
          <div className="rounded-lg w-full h-32 border border-neutral-300" />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-5">
      <div className="flex-1 overflow-y-auto">
        <div className="space-y-4">
          <div className="flex justify-start">
            <div className="rounded-lg p-3 bg-neutral-100 text-neutral-900 max-w-[75%] xl:max-w-full">
              <pre className="font-body text-sm whitespace-pre-wrap">
                Hello! I'm here to look up information in your policy documents. What would you like
                to know?
              </pre>
            </div>
          </div>
          {messages.map((message) => (
            <div
              key={message.id}
              className={clsx(
                'flex',
                message.role === PolicyConversationMessageRole.User
                  ? 'justify-end'
                  : 'justify-start'
              )}
            >
              <div
                className={clsx(
                  'rounded-lg p-3 flex flex-col max-w-[75%] xl:max-w-full',
                  message.role === PolicyConversationMessageRole.User
                    ? 'bg-sky-500 text-white'
                    : 'bg-neutral-100 text-neutral-900'
                )}
              >
                <pre className="font-body text-sm whitespace-pre-wrap">{message.content}</pre>
                {message.attachments.length > 0 && !showAttachments[message.id] && (
                  <HeadlessButton
                    onClick={() =>
                      setShowAttachments({
                        ...showAttachments,
                        [message.id]: true
                      })
                    }
                    className="text-xs font-medium text-primary-500 hover:text-primary-800 inline-flex items-center gap-1 mt-2"
                  >
                    Show relevant quotes <ArrowRightIcon className="h-3 w-3" />
                  </HeadlessButton>
                )}
                {message.attachments.length > 0 && showAttachments[message.id] && (
                  <div className="flex flex-col gap-2 mt-4">
                    {message.attachments?.map((attachment) => (
                      <div
                        key={attachment.content}
                        className="text-xs border-l-4 border-neutral-200 px-2 py-0.5"
                      >
                        {attachment.content}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
          {sendMessageLoading && (
            <>
              <div className="flex justify-end">
                <div className="rounded-lg p-3 bg-sky-500 text-white">
                  <pre className="font-body text-sm whitespace-pre-wrap">{stagedMessage}</pre>
                </div>
              </div>
              <div className="flex justify-start">
                <div className="rounded-lg p-3 bg-neutral-100 text-neutral-900">
                  <div className="flex items-center space-x-1">
                    <div className="w-2 h-2 bg-neutral-400 rounded-full animate-pulse"></div>
                    <div className="w-2 h-2 bg-neutral-400 rounded-full animate-pulse delay-75"></div>
                    <div className="w-2 h-2 bg-neutral-400 rounded-full animate-pulse delay-150"></div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="mt-4 text-center">
            <p className="text-xs text-neutral-400">
              Please note: This assistant will attempt to find information in your policy. However,
              it is not a licensed agent. Always check your policy documents or schedule a call with
              an Oyster agent for the most accurate information.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <form onSubmit={handleSubmit} className="relative">
          <Fieldset>
            <FieldGroup>
              <Field>
                <Label className="sr-only">Ask a question or followup</Label>
                <Textarea
                  rows={4}
                  placeholder="Ask a question or followup..."
                  resizable={false}
                  disabled={sendMessageLoading}
                  value={editorText}
                  onChange={(e) => setEditorText(e.target.value)}
                />
                {sendMessageError && <ErrorMessage>{sendMessageError.message}</ErrorMessage>}
              </Field>
            </FieldGroup>
          </Fieldset>

          <Button
            type="submit"
            color="sky"
            className="mt-4 justify-end"
            disabled={sendMessageLoading}
          >
            Send Message
          </Button>
        </form>
      </div>
    </div>
  );
};
