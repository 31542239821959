import * as React from 'react';
import styled from 'styled-components';
import { IoArrowForward, IoDownloadOutline, IoOpenOutline } from 'react-icons/io5';
import { Badge } from '@oysterjs/ui/Badge';

import { PageTitle } from '@oysterjs/ui/Page/section';
import { Action, ActionsContainer } from './table';
import config from '@oysterjs/core/config';
import {
  BusinessInsuranceType,
  BusinessOperationType,
  Merchant,
  MerchantUser,
  ProductType
} from '@oysterjs/types';
import {
  Merchant as GraphQLMerchant,
  PolicyState,
  FileRole,
  GetCertificateQuery,
  GetCertificateDocument,
  File,
  GenerateCertificateInput,
  useGenerateCertificateMutation
} from '@oysterjs/types/merchant/graphql/types-and-hooks';
import {
  GetMerchantBusinessPolicyDocument,
  GetMerchantBusinessPolicyQuery
} from '../../../types/graphql';
import { getDisplayInsuranceType } from '@oysterjs/types/merchant/graphql/map';
import { openPageInNewTab } from '@oysterjs/core/window';
import { useHistory, useLocation } from 'react-router';
import { SlideOut } from '@oysterjs/ui/Modal/slideout';
import ErrorBoundary from '@oysterjs/ui/ErrorBoundary';
import { Loadable } from '@oysterjs/ui/Loadable';
import { getMerchantGraphQLClient } from '@oysterjs/core/api/merchant';
import { FormColumn, FormContainer, FormRow, FormRowHeader } from '@oysterjs/ui/Form/builder';
import { ErrorDisplay, TextAreaInput, TextInput } from '@oysterjs/ui/Form/text';
import { AddressInputForm } from '@oysterjs/ui/Form/address';
import { Button, ButtonContainer } from '@oysterjs/ui/Button';
import { Button as NewButton } from '../../../components/button';
import { Checkbox } from '@oysterjs/ui/Form/checkbox';
import {
  RequirementCheckboxContainer,
  RequirementContentContainer,
  RequirementDescription,
  RequirementItemContainer,
  RequirementTitle
} from '@oysterjs/ui/common';
import { Banner } from '@oysterjs/ui/Banner';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { DocumentPlusIcon } from '@heroicons/react/24/outline';
import { BusinessPolicyFieldsFragment } from '../../../types/graphql';

const getFormId = (): string => {
  switch (config().environment) {
    case 'production':
      return 'vtaJwrn7SEus';
    default:
      return 'ohCv6cYeRaus';
  }
};

const toNumber = (s?: string) => s?.replace(/[^\d.]/g, '');

const getFilloutParameters = (merchant: Merchant, merchantUser: MerchantUser) => ({
  'data-first_name': merchantUser.FirstName,
  'data-last_name': merchantUser.LastName,
  'data-email': merchantUser.Email,
  'data-phone': merchant.BusinessProfile.Phone,
  'data-merchant_id': merchant.ID,
  'data-business_name': merchant.BusinessProfile.Name,
  'data-dba_name': merchant.BusinessProfile.DBA,
  'data-website': merchant.BusinessProfile.Domain,
  'data-address_line_1': merchant.BusinessProfile.Address.AddressLine1,
  'data-address_line_2': merchant.BusinessProfile.Address.AddressLine2,
  'data-address_city': merchant.BusinessProfile.Address.City,
  'data-address_state': merchant.BusinessProfile.Address.Zone,
  'data-address_zip_code': merchant.BusinessProfile.Address.PostalCode,
  'data-business_type':
    {
      [ProductType.bike]: 'BIKES',
      [ProductType.jewelry]: 'JEWELRY',
      [ProductType.electronics]: 'ELECTRONICS'
    }[merchant.BusinessProfile.ProductVerticals?.[0] || ''] ||
    merchant.BusinessProfile.ProductVerticals?.[0],
  'data-insurance_types': merchant.BusinessProfile.Personalization.BusinessInsuranceTypes?.map(
    (t) =>
      ({
        [BusinessInsuranceType.BusinessOwners]: 'BUSINESS_OWNERS',
        [BusinessInsuranceType.CommercialAuto]: 'AUTO',
        [BusinessInsuranceType.Cyber]: 'CYBER',
        [BusinessInsuranceType.DirectorsAndOfficers]: 'DIRECTORS_OFFICERS',
        [BusinessInsuranceType.GeneralLiability]: 'GENERAL_LIABILITY',
        [BusinessInsuranceType.JewelersBlock]: 'JEWELERS_BLOCK',
        [BusinessInsuranceType.Property]: 'PROPERTY',
        [BusinessInsuranceType.Rental]: 'RENTAL',
        [BusinessInsuranceType.ShippingAndTransportation]: 'SHIPPING',
        [BusinessInsuranceType.UmbrellaExcess]: 'UMBRELLA',
        [BusinessInsuranceType.WorkersCompensation]: 'WORKERS_COMP'
      })[t] ||
      merchant.BusinessProfile.Personalization.BusinessInsuranceTypesOtherDesc ||
      t
  ).join(','),
  'data-operation_types': merchant.BusinessProfile.Personalization.BusinessOperationTypes?.map(
    (t) =>
      ({
        [BusinessOperationType.RetailECommerce]: 'RETAIL',
        [BusinessOperationType.ServiceOrRepair]: 'SERVICE',
        [BusinessOperationType.Wholesale]: 'WHOLESALE',
        [BusinessOperationType.Rental]: 'RENTAL',
        [BusinessOperationType.Manufacturing]: 'MANUFACTORING_DESIGN'
      })[t] ||
      merchant.BusinessProfile.Personalization.BusinessOperationTypesOtherDesc ||
      t
  ).join(','),
  'data-own_brand': merchant.BusinessProfile.Personalization.BusinessManufactureOrWholesaleOwnBrand,
  'data-revenue_retail': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownRetail
  ),
  'data-revenue_service': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownServiceOrRepair
  ),
  'data-revenue_wholesale': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownWholesale
  ),
  'data-revenue_rental': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownRental
  ),
  'data-revenue_manufacturing': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownManufacturing
  ),
  'data-revenue_other': toNumber(
    merchant.BusinessProfile.Personalization.BusinessRevenueBreakdownOther
  ),
  'data-total_employee_payroll': toNumber(
    merchant.BusinessProfile.Personalization.BusinessTotalPayroll
  ),
  'data-number_of_losses': toNumber(
    merchant.BusinessProfile.Personalization.BusinessNumberOfPriorLosses
  )
});

const GetInsurance = (props: { merchant: Merchant; merchantUser: MerchantUser }) => {
  React.useEffect(() => {
    // Create script component.
    const script = document.createElement('script');
    script.id = 'fillout-embed';
    script.src = `//server.fillout.com/embed/v1/`;
    script.defer = true;

    // Add the script to the DOM
    document.head.appendChild(script);

    // Remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const prefilledParameters = getFilloutParameters(props.merchant, props.merchantUser);

  return (
    <>
      <p className="text-base max-w-[750px] mt-0">
        Submit the form to get a tailored quote for your business. Our commercial insurance team of
        experts will reach out soon upon submission.
      </p>
      <div
        className="max-w-[750px] mt-4"
        data-fillout-id={getFormId()}
        data-fillout-embed-type="standard"
        data-fillout-inherit-parameters
        data-fillout-dynamic-resize
        {...prefilledParameters}
      />
      <p className="text-sm mt-4 text-neutral-700">
        <b className="font-medium">Need help? </b>
        <a
          target="_blank"
          href="https://meetings.hubspot.com/gunnar-reinig/commercial-insurance-consultation"
          className="text-primary-600 hover:text-primary-900"
        >
          Schedule a call
        </a>{' '}
        with us!
      </p>
    </>
  );
};

const InsuranceFormSubmitted = () => (
  <>
    <p className="text-base max-w-[600px] text-neutral-900 mt-0">
      Thank you for submitting your information. Our commercial insurance team of experts will reach
      out soon.
    </p>
    <p className="text-sm mt-4 text-neutral-700">
      <b className="font-medium">Need help? </b>
      <a
        target="_blank"
        href="https://meetings.hubspot.com/gunnar-reinig/commercial-insurance-consultation"
        className="text-primary-600 hover:text-primary-900"
      >
        Schedule a call
      </a>{' '}
      with us!
    </p>
  </>
);

const statuses = {
  [PolicyState.Binding]: 'text-primary-700 bg-primary-50 ring-primary-600/20',
  [PolicyState.Inforce]: 'text-green-700 bg-green-50 ring-green-600/20',
  [PolicyState.Expired]: 'text-neutral-600 bg-neutral-50 ring-neutral-500/10',
  [PolicyState.Canceled]: 'text-red-700 bg-red-50 ring-red-600/10'
};

const ManageBusinessInsurance = (props: {
  merchant: Merchant;
  merchantUser: MerchantUser;
  graphMerchant: GraphQLMerchant;
  policies: BusinessPolicyFieldsFragment[];
}) => {
  const urlParams = new URLSearchParams(
    Object.fromEntries(
      Object.entries(getFilloutParameters(props.merchant, props.merchantUser)).map(
        ([key, value]) => [key.replace(/^data-/, ''), value]
      )
    )
  );

  const filloutLink =
    config().environment === 'production'
      ? 'https://www.withoyster.com/business-insurance-app'
      : `https://form.fillout.com/t/${getFormId()}?${urlParams.toString()}`;

  const certificates = props.graphMerchant.files
    .filter((f) => f.role === FileRole.Certificate)
    .filter((f) => !!f.details?.certificateNumber);

  return (
    <div className="space-y-8">
      <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div>
              <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                Your Coverage
              </h3>
              <p className="text-sm text-neutral-500">
                Details about your business insurance policies
              </p>
            </div>
            <div className="flex-shrink-0 mt-2 sm:mt-0">
              <NewButton href={filloutLink} type="button" color="sky">
                Apply for new coverage
              </NewButton>
            </div>
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full divide-y divide-neutral-200">
                <thead className="bg-neutral-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-neutral-900 sm:pl-6 lg:pl-8"
                    >
                      Coverage
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Carrier
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                    >
                      Expiration Date
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-neutral-200 bg-white">
                  {props.policies.map((policy) => (
                    <tr key={policy.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6 lg:pl-8">
                        <div className="text-sm font-medium text-neutral-900">
                          {getDisplayInsuranceType(policy.type)}
                        </div>
                        <div className="text-xs mt-1 text-neutral-500">
                          {policy.policyNumber || 'N/A'}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                        <div
                          className={clsx(
                            statuses[policy.state],
                            'inline-flex rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset'
                          )}
                        >
                          {titleCase(policy.state)}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                        {policy.carrier.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                        {!policy.expiresAt || isNaN(new Date(policy.expiresAt).getTime())
                          ? 'N/A'
                          : new Intl.DateTimeFormat('en-US').format(new Date(policy.expiresAt))}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                        <NavLink
                          to={`/insurance/overview/policy/${policy.id}`}
                          className="text-primary-600 hover:text-primary-900 cursor-pointer"
                        >
                          View
                          <span className="sr-only">, {getDisplayInsuranceType(policy.type)}</span>
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="divide-y divide-neutral-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div>
              <h3 className="text-base/4 font-sans font-semibold leading-6 m-0 text-neutral-900">
                Your Certificates
              </h3>
              <p className="text-sm text-neutral-500">
                Certificates of insurance you have created with Oyster
              </p>
            </div>
            {certificates.length > 0 && (
              <div className="flex-shrink-0 mt-2 sm:mt-0">
                <NewButton href="/insurance/certificates/create" type="button" color="sky">
                  Request Certificate
                </NewButton>
              </div>
            )}
          </div>
        </div>
        <div className="px-4 sm:px-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              {certificates.length === 0 && (
                <div className="text-center p-8">
                  <DocumentPlusIcon className="mx-auto h-12 w-12 text-neutral-400" />
                  <h4 className="mt-2 text-sm font-semibold text-neutral-900">No certiciates</h4>
                  <p className="mt-1 text-sm text-neutral-500">
                    Get started by creating a new certificate.
                  </p>
                  <div className="mt-6">
                    <NewButton href="/insurance/certificates/create" type="button" color="sky">
                      Create Certificate
                    </NewButton>
                  </div>
                </div>
              )}
              {certificates.length > 0 && (
                <table className="min-w-full divide-y divide-neutral-200">
                  <thead className="bg-neutral-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-neutral-900 sm:pl-6 lg:pl-8"
                      >
                        Certificate Holder
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                      >
                        Certificate Number
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-neutral-900"
                      >
                        Creation Date
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-neutral-200 bg-white">
                    {certificates.map((certificate) => (
                      <tr key={certificate.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-neutral-900 sm:pl-6 lg:pl-8">
                          {certificate.details?.certificateHolder.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {certificate.details?.certificateNumber}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-neutral-500">
                          {new Intl.DateTimeFormat('en-US').format(new Date(certificate.createdAt))}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                          <NavLink
                            to={`/insurance/certificates/certificate/${certificate.details?.certificateNumber}`}
                            className="text-primary-600 hover:text-primary-900"
                          >
                            View
                            <span className="sr-only">
                              , {certificate.details?.certificateNumber}
                            </span>
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getPolicyBadge = (state: PolicyState) => {
  switch (state) {
    case PolicyState.Binding:
      return <Badge color="rgba(252, 227, 193)" textColor="rgba(214, 131, 0)" label="Pending" />;

    case PolicyState.Inforce:
      return <Badge color="rgba(189, 234, 205)" textColor="rgba(57, 145, 88)" label="Active" />;

    case PolicyState.Expired:
      return <Badge color="rgb(255, 193, 185)" textColor="rgb(201, 67, 48)" label="Expired" />;

    default:
      return <></>;
  }
};

const titleCase = (s: string) =>
  s
    .split(/[\s_]/g)
    .map((part) => part[0].toUpperCase() + part.substring(1).toLowerCase())
    .join(' ');

const unescapeSafe = (input: string) => {
  const doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
};

const ViewBusinessPolicy = (props: { policy: BusinessPolicyFieldsFragment }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
    {[
      ['Carrier', props.policy.carrier.name],
      [
        'Premium',
        new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' }).format(
          props.policy.pricing.premium
        )
      ],
      [
        'Total Charge',
        new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' }).format(
          props.policy.pricing.total
        )
      ],
      ['Payment Mode', titleCase(props.policy.pricing.billingType)]
    ].map(([label, description]) => (
      <div key={label} style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <div style={{ fontWeight: 500 }}>{label}</div>
        <div style={{ color: '#666666', fontSize: '0.9em' }}>{description}</div>
      </div>
    ))}
    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <div style={{ fontWeight: 500 }}>Documents</div>
      {!props.policy.files.length && (
        <div style={{ color: '#666666', fontSize: '0.9em' }}>No documents available yet</div>
      )}
      {!!props.policy.files.length && (
        <ul
          style={{
            color: '#666666',
            fontSize: '0.9em',
            listStyle: 'none',
            margin: '0',
            padding: '0'
          }}
        >
          {props.policy.files
            .filter((f) => !!f.url)
            .map((f) => (
              <li>
                <a href={f.url || ''} target="_BLANK">
                  {titleCase(f.role)} <IoOpenOutline />
                </a>
              </li>
            ))}
        </ul>
      )}
    </div>
  </div>
);

const ViewCertificate = (props: { certificate: File }) => (
  <>
    <ActionsContainer>
      <Action
        onClick={() =>
          openPageInNewTab(
            `${config().serviceBaseUrl.partners}/certificate/${
              props.certificate.details?.certificateNumber
            }`
          )
        }
      >
        Open Sharable Link <IoOpenOutline />
      </Action>
      <Action onClick={() => openPageInNewTab(props.certificate.url || '')}>
        Download Certificate <IoDownloadOutline />
      </Action>
    </ActionsContainer>
    <div
      style={{ display: 'flex', flexDirection: 'column', gap: '16px', padding: '24px 0px 0px 0px' }}
    >
      {[
        ['Insured Name', unescapeSafe(props.certificate.details?.insured.name || '')],
        ['Insured Address', unescapeSafe(props.certificate.details?.insured.address || '')],
        [
          'Certificate Holder Name',
          unescapeSafe(props.certificate.details?.certificateHolder.name || '')
        ],
        [
          'Certificate Holder Address',
          unescapeSafe(props.certificate.details?.certificateHolder.address || '')
        ],
        [
          'Description of Operations',
          unescapeSafe(props.certificate.details?.descriptionOfOperations || '')
        ]
      ].map(
        ([label, description]) =>
          !!description && (
            <div key={label} style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <div style={{ fontWeight: 500 }}>{label}</div>
              <div style={{ color: '#666666', fontSize: '0.9em' }}>{description}</div>
            </div>
          )
      )}
    </div>
  </>
);

const CreateCertificate = () => {
  const history = useHistory();
  const [generateCertificate, { loading }] = useGenerateCertificateMutation();
  const [componentError, setComponentError] = React.useState<Error>();
  const [needsAgentAction, setNeedsAgentAction] = React.useState(false);
  const [form, setForm] = React.useState<GenerateCertificateInput>({
    name: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      zone: '',
      postalCode: ''
    },
    additionalInsured: false,
    additionalInsuredRelationship: '',
    notes: ''
  });

  const handleSubmit = async () => {
    try {
      setComponentError(undefined);
      const res = await generateCertificate({ variables: { req: form } });
      if (res.errors?.length) {
        throw new Error(res.errors[0].message);
      }
      if (res.data && !res.data.generateCertificate) {
        setNeedsAgentAction(true);
      } else if (res.data?.generateCertificate?.details?.certificateNumber) {
        history.push(
          `/insurance/certificates/certificate/${res.data?.generateCertificate?.details?.certificateNumber}`
        );
      }
    } catch (e) {
      setComponentError(e as Error);
    }
  };

  if (needsAgentAction) {
    return (
      <p>
        Your request has been received. An agent will follow up via email with your completed
        certificate.
      </p>
    );
  }

  return (
    <FormContainer>
      <FormRowHeader
        title="Certificate Holder"
        description="Enter the name of the party that will receive and hold the certificate."
      />
      <FormRow>
        <FormColumn>
          <TextInput
            style={{ maxWidth: '300px' }}
            value={form.name}
            onChange={(e) => {
              const val = e.currentTarget.value;
              setForm((prev) => ({ ...prev, name: val }));
            }}
          />
        </FormColumn>
      </FormRow>
      <FormRowHeader
        title="Certificate Holder Address"
        description="Enter the address of the party that will receive and hold the certificate."
      />
      <AddressInputForm
        onChange={(address) =>
          setForm((prev) => ({
            ...prev,
            address: {
              line1: address.streetAddress || '',
              city: address.city || '',
              zone: address.state || '',
              postalCode: address.zipCode || ''
            }
          }))
        }
      />
      <FormRow>
        <RequirementItemContainer
          style={{ padding: '0' }}
          onClick={(e) => {
            e.stopPropagation();
            setForm((prev) => ({ ...prev, additionalInsured: !prev.additionalInsured }));
          }}
        >
          <RequirementCheckboxContainer>
            <Checkbox
              label="Add the certificate holder as an additional insured"
              checked={form.additionalInsured}
              onChange={() => {
                setForm((prev) => ({ ...prev, additionalInsured: !prev.additionalInsured }));
              }}
            />
          </RequirementCheckboxContainer>
          <RequirementContentContainer>
            <RequirementTitle>Add the certificate holder as an additional insured</RequirementTitle>
            <RequirementDescription>
              Check this box if you want coverage to extend to this party.{' '}
              <b>
                Do not check this unless specifically requested by the certificate holder, as this
                may take several business days to process.
              </b>
            </RequirementDescription>
          </RequirementContentContainer>
        </RequirementItemContainer>
      </FormRow>
      {form.additionalInsured && (
        <>
          <FormRowHeader
            title="Relationship to Insured"
            description="Describe the relationship of the certificate holder to the primary insured on the policy."
          ></FormRowHeader>
          <FormRow>
            <FormColumn>
              <TextInput
                style={{ maxWidth: '300px' }}
                value={form.additionalInsuredRelationship || ''}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  setForm((prev) => ({ ...prev, additionalInsuredRelationship: val }));
                }}
              />
            </FormColumn>
          </FormRow>
          <FormRow>
            <Banner
              title="Additional Review Required"
              description="In order to make sure your policy extends coverage to this party, Oyster will perform a manual review. In most cases, you'll receive your certificate within 24 hours, but the process may take up to several business days depending on complexity. Our agents will follow up via email."
            />
          </FormRow>
        </>
      )}
      <FormRowHeader
        title="Notes"
        description="Additional notes to include for the Oyster team to review."
      />
      <TextAreaInput
        rows={3}
        value={form.notes}
        onChange={(e) => {
          const val = e.currentTarget.value;
          setForm((prev) => ({ ...prev, notes: val }));
        }}
      />
      <FormRow>
        <ButtonContainer>
          <Button primary icon={<IoArrowForward />} loading={loading} onClick={handleSubmit}>
            Request Certificate
          </Button>
        </ButtonContainer>
      </FormRow>
      {componentError && <ErrorDisplay>{componentError.message}</ErrorDisplay>}
    </FormContainer>
  );
};

export const InsurancePage = (props: {
  merchant: Merchant;
  merchantUser: MerchantUser;
  graphMerchant: GraphQLMerchant;
  policies?: BusinessPolicyFieldsFragment[];
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <PageTitle
        title="Business Insurance"
        description="View and manage your business insurance policies with Oyster"
      />

      {!props.merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted &&
        !props.policies?.length && (
          <GetInsurance merchant={props.merchant} merchantUser={props.merchantUser} />
        )}
      {props.merchant.BusinessProfile.Personalization.BusinessInsuranceFilloutFormSubmitted &&
        !props.policies?.length && <InsuranceFormSubmitted />}
      {!!props.policies?.length && (
        <ManageBusinessInsurance
          merchant={props.merchant}
          merchantUser={props.merchantUser}
          graphMerchant={props.graphMerchant}
          policies={props.policies}
        />
      )}

      <SlideOut
        showing={/^\/insurance\/overview\/policy\/.+$/.test(location.pathname)}
        onClose={() => history.replace('/insurance/overview')}
        onBack={() => history.replace('/insurance/overview')}
        title="View Policy"
      >
        <ErrorBoundary forceMobile>
          {!location.pathname.match(/\/policy\/(.+)$/)?.[1] ? null : (
            <Loadable
              request={getMerchantGraphQLClient()
                .query<GetMerchantBusinessPolicyQuery>({
                  query: GetMerchantBusinessPolicyDocument,
                  variables: {
                    id: location.pathname.match(/\/policy\/(.+)$/)?.[1]
                  }
                })
                .then((res) => {
                  if (res.data?.businessPolicy) {
                    return res.data.businessPolicy;
                  }

                  throw new Error('Policy not found');
                })}
            >
              {(data) => (
                <PolicySlideoutContainer
                  title={getDisplayInsuranceType(data.type)}
                  description={data.policyNumber}
                  badge={getPolicyBadge(data.state)}
                >
                  <ViewBusinessPolicy policy={data} />
                </PolicySlideoutContainer>
              )}
            </Loadable>
          )}
        </ErrorBoundary>
      </SlideOut>

      <SlideOut
        showing={/^\/insurance\/certificates\/certificate\/.+$/.test(location.pathname)}
        onClose={() => history.replace('/insurance/certificates')}
        onBack={() => history.replace('/insurance/certificates')}
        title="View Certificate"
      >
        <ErrorBoundary forceMobile>
          {!location.pathname.match(/\/certificate\/(.+)$/)?.[1] ? null : (
            <Loadable
              request={getMerchantGraphQLClient()
                .query<GetCertificateQuery>({
                  query: GetCertificateDocument,
                  variables: {
                    certificateNumber: location.pathname.match(/\/certificate\/(.+)$/)?.[1]
                  }
                })
                .then((res) => {
                  if (res.data?.certificate?.details) {
                    return res.data.certificate;
                  }

                  throw new Error('Certificate not found');
                })}
            >
              {(data) => (
                <PolicySlideoutContainer
                  title="Certificate of Insurance"
                  description={data.details?.certificateNumber || ''}
                  badge={getPolicyBadge(PolicyState.Inforce)}
                >
                  <ViewCertificate certificate={data as File} />
                </PolicySlideoutContainer>
              )}
            </Loadable>
          )}
        </ErrorBoundary>
      </SlideOut>

      <SlideOut
        showing={/^\/insurance\/certificates\/create$/.test(location.pathname)}
        onClose={() => history.replace('/insurance/certificates')}
        onBack={() => history.replace('/insurance/certificates')}
      >
        <ErrorBoundary forceMobile>
          <PolicySlideoutContainer
            title="Create a Certificate"
            description="Automatically produce a document that certifies your insurance coverage for a third party"
          >
            <CreateCertificate />
          </PolicySlideoutContainer>
        </ErrorBoundary>
      </SlideOut>
    </>
  );
};

const SlideoutHeader = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 0px;
`;

interface PolicySlideoutContainerProps {
  title: string;
  description: string;
  badge?: JSX.Element;
}

export const PolicySlideoutContainer = (
  props: React.PropsWithChildren<PolicySlideoutContainerProps>
) => (
  <div style={{ padding: '0px 20px' }}>
    <SlideoutHeader>
      <h1>{props.title}</h1>
      {props.badge}
    </SlideoutHeader>
    <p style={{ margin: '0 0 1em 0', fontSize: '0.9em', color: '#666666' }}>{props.description}</p>
    {props.children}
    <div style={{ padding: '20px 0px' }}>
      <p style={{ fontSize: '0.9em', color: '#333333' }}>
        <b style={{ fontWeight: 500 }}>Need help?</b>{' '}
        <a
          target="_blank"
          href={`https://meetings.hubspot.com/gunnar-reinig/commercial-insurance-consultation`}
        >
          Schedule a call
        </a>{' '}
        with us!
      </p>
    </div>
  </div>
);
