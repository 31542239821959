import React from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';

const NavigationContainer = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0 0 0 0;
  flex-direction: row;
  gap: 24px;
  width: 100%;
  box-shadow: 0 1px 0 0 #f6f6f6;
`;

const NavigationItemContainer = styled.li`
  display: block;
  text-align: center;

  a,
  a:visited {
    display: block;
    height: 100%;
    padding: 0px 0px 10px 0px;
    box-sizing: border-box;
    font-size: 0.9em;
    color: #666666;
    text-decoration: none;
  }

  a.active-tab {
    color: #0ea5e9;
    font-weight: bold;
    box-shadow: 0 2px 0 0 #0ea5e9;
  }
`;

interface PageNavigationRoute {
  path: string;
  title: string;
  count?: number;
  exact?: boolean;
  absolute?: boolean;
  render: () => JSX.Element;
}

interface PageNavigationProps {
  routes: PageNavigationRoute[];
  redirect?: Record<string, string>;
}

export const PageNaviation = (props: React.PropsWithChildren<PageNavigationProps>) => {
  const { url, path } = useRouteMatch();
  const concatPath = (base: string, relative: string) =>
    `${base.replace(/\/+$/, '')}/${relative.replace(/\/+$/, '').replace(/^\/+/, '')}`;

  return (
    <>
      <nav>
        <NavigationContainer>
          {props.routes.map((route) => (
            <NavigationItemContainer>
              <NavLink to={concatPath(url, route.path)} activeClassName="active-tab">
                {route.title}
              </NavLink>
            </NavigationItemContainer>
          ))}
        </NavigationContainer>
      </nav>
      <div style={{ padding: '10px 0px 0px 0px' }}>
        <Switch>
          {props.routes.map((route) => (
            <Route
              exact={route.exact}
              key={route.path}
              path={concatPath(path, route.path)}
              render={route.render}
            />
          ))}
          {Object.entries(props.redirect || {}).map(([from, to]) => (
            <Redirect
              exact
              key={from + to}
              from={concatPath(path, from)}
              to={concatPath(path, to)}
            />
          ))}
        </Switch>
      </div>
    </>
  );
};
